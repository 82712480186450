<template>
  <div class="about">
    <el-row
      class="topbg"
      style="margin-left: 0px; margin-right: 0px; margin-top: 18px"
      :gutter="20"
    >
      <el-row :gutter="0" style="margin-top: 18px">
        <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2"> &nbsp; </el-col>
        <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20">
          <el-row>
            <h2>{{ $t("Referral.InviteFriends") }}</h2>
            <h2>{{ $t("Referral.EarnRewards") }}</h2>
            <p>{{ $t("Referral.InviteFriendsdec") }}</p>
          </el-row>
          <el-row
            style="
              display: block;

              margin: 0.5rem auto auto;
              height: 35rem;

              background-color: white;
              box-shadow: #efefef 0px 26px 32px;
              border-radius: 24px;
              padding: 2.5rem;
            "
          >
            <el-row
              style="
                height: 2rem;
                line-height: 2rem;
                line-height: 1.5;
                color: #58667e;
                font-size: 1rem;
                font-weight: 600;
              "
            >
              {{ $t("Referral.TotalReferrals") }}
            </el-row>
            <el-row
              style="
                height: 6rem;
                line-height: 4rem;
                font-size: 2.5rem;
                font-weight: 600;
              "
            >
              {{ TotalReferrals }}
            </el-row>
            <el-row
              style="
                height: 2rem;
                line-height: 2rem;
                line-height: 1.5;
                color: #58667e;
                font-size: 1rem;
                font-weight: 600;
              "
            >
              {{ $t("Referral.ShareReferralLink") }}
            </el-row>
            <el-row style="text-align: center">
              <vue-qr
                class="qr-code"
                :logoSrc="imageUrl"
                :text="qrCodeUrl"
                :size="230"
              />
            </el-row>
            <el-row
              style="
                border-radius: 5rem;
                background-color: #eff2f5;
                margin-top: 1rem;
                height: 4rem;
                width: 100%;
                text-align: center;
              "
            >
              <el-row
                ><el-col
                  style="
                    width: 11.2rem;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                    font-weight: 500;
                    float: left;
                    height: 4rem;
                    line-height: 4rem;
                    font-size: 0.8rem;
                    font-weight: bold;
                  "
                  :xs="18"
                  :sm="18"
                  :md="18"
                  :lg="18"
                  :xl="18"
                  >{{ qrCodeUrl }}
                </el-col>
                <el-col
                  :xs="3"
                  :sm="3"
                  :md="3"
                  :lg="3"
                  :xl="3"
                  style="
                    line-height: 4rem;
                    font-size: 0.8rem;
                    font-weight: bold;
                    color: blue;
                    float: right;
                    margin-right: 20px;
                  "
                >
                  <span @click="copy">{{ $t("Referral.Copy") }}</span>
                </el-col>
              </el-row>
            </el-row>
          </el-row>
          <el-row>
            <h3>{{ $t("Referral.YourReferrals") }}</h3>
          </el-row>
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            empty-text="--"
          >
            <el-table-column prop="address" :label="$t('Referral.User')">
            </el-table-column>
            <el-table-column prop="Assets" :label="$t('Referral.Assets')">
            </el-table-column>
            <el-table-column prop="amount" :label="$t('Referral.Rewards')">
            </el-table-column>
          </el-table>
        </el-col>

        <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1"> &nbsp; </el-col>
      </el-row>
    </el-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
import vueQr from "vue-qr";

export default {
  components: { vueQr },
  /**
   *
   */
  data() {
    return {
      text: "111",
      tableData: null,
      falg: true,
      TotalReferrals: 0,
      qrCodeUrl: window.location.href, // 二维码的内容
      imageUrl: require("../assets/bhp.png"), // icon路径
    };
  },
  created() {
    //Promise
  },
  mounted() {
    this.gettableaf();
  },
  /**
   * 定义函数
   */
  methods: {
    async gettableaf() {
      var that = this;
      let post = await axios({
        method: "post",
        url: "/api/sendapi/gettableaf",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          id: 1,
        },
      })
        .then(function (response) {
          console.log(response);
          if (response.data.code == 0) {
            that.tableData = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async aftotuswe() {
      var that = this;
      let post = await axios({
        method: "post",
        url: "/api/sendapi/aftotuswe",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          id: 1,
        },
      })
        .then(function (response) {
          console.log(response);
          if (response.data.code == 0) {
            that.TotalReferrals = response.data.data;
            console.log(response);
          } else {
            Rewards = 0;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async copy() {
      {
        this.$copyText(this.qrCodeUrl).then(
          (e) => {
            this.$message({
              message: "copy successfully",
              type: "success",
            });
          },
          (e) => {
            this.$message({
              message: "replication failed",
              type: "warning",
            });
            console.log("replication failed", e);
          }
        );
      }
    },
    async checklogin() {
      var that = this;
      let post = await axios({
        method: "post",
        url: "/api/sendapi/checklogin",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          id: 1,
        },
      })
        .then(function (response) {
          console.log(response);
          if (response.data.code == 0) {
            that.qrCodeUrl =
              "https://" +
              window.location.hostname +
              "/#/" +
              response.data.info;
            console.log(this.qrCodeUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  mounted: function () {
    this.checklogin();
    this.gettableaf();
    this.aftotuswe();
    console.log(this.qrCodeUrl);
  },
  watch: {
    defaultAccount: {
      handler(newaccout, oldaccount) {
        this.checklogin();
        this.gettableaf();
        console.log(this.qrCodeUrl);
      },
    },
  },
  computed: {
    ...mapState([
      "usdtToken",
      "usdcToken",
      "contracts",
      "usdccontracts",
      "web3",
      "defaultNetWork",
      "defaultAccount",
    ]),
  },
};
</script>
<style scoped>
.topbg {
  background: url(../assets/referralbg.49cb867c.png) no-repeat;
  height: 32.5rem;
  width: 100%;

  background-size: 100% 100%;
}
</style>
