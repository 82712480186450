export default{

menus: {
 home: ' Casa ',
 mining: ' Defi mining ',

 Referral: 'Referral', 
 account: 'Account',
 ConnectWallet: 'Collegare il portafoglio',
 
 },
 home: {
  jion: 'Unisciti a Defi Mining da un posto',
  jiondefi: ' DEFI Hub è il modo più semplice per costruire e gestire l\'intero mining DeFi da un posto.',
  jiondefit: 'Scopri il mondo della finanza decentralizzata oggi. ',
  mining: 'Defi mining', 
  Referral: ' Referral ', 
  bannertitle: 'Privacy e sicurezza prima' , 
  bannerdesc: ' Con DEFI Hub, hai il controllo, sempre. Non abbiamo account utente e invece lavoriamo direttamente con i portafogli Web3 come TronLink  , TrustWallet , SafePal, Coinbase.  Questo significa che non possediamo le tue chiavi private e non possiamo mai accedere ai tuoi fondi. Non associamo i dati sul portafoglio incrociato e non condividiamo mai le tue informazioni con terze parti. '
  ,banneroepn:'Globale, libero e aperto a chiunque',
  bannerappnum:'migliaia',
  bannerappopen:' di protocolli DEFI supportati',
  bannertrade:' $ 1 miliardo+ ',

  bannerapptrdl:'Volume scambiato a partire dal febbraio 2023',
  bannerusernum:'20 miliardo+ ',
  bannerusernumlt:' Utenti attivi mensili a febbraio 2023',
  bannerusernumlt:'Hai domande?',
  Partner:'Fidati di',
  toptips:'Diventa un validatore di transazioni e contribuisci a proteggere il futuro di BSC ETH Tron',
toptipsleft:'Attraverso la rete BSC ETH Tron, puoi diventare un validatore di transazioni.',
toptipsre:'Fornisci la verifica delle transazioni per la rete BSC ETH Tron e ricevi ricompense continue.',

  
  },Referral: {
      InviteFriends: 'Invita gli amici',
      EarnRewards : 'Invita gli amici ',
      InviteFriendsdec: 'Invita i tuoi amici a unirsi e otterrai amici del 30% di premi di mining.',
      TotalReferrals: 'Referral totali',

    ShareReferralLink: 'Condividi il collegamento di riferimento',
    
    Copy: ' copia ',
    YourReferrals: 'I tuoi referral',
    User: 'Utente',
    Assets: 'Risorse',
    Rewards: 'Premi',
    Nomoredata: 'Niente più dati',
 
    Referralwork: '  Come funziona il programma di riferimento? ',
    Referralworkdec: ' Il programma di riferimento consente di creare collegamenti di referral unici che invitano i tuoi amici a DefI mining. Se chiunque fa clic sul collegamento e defi mining, saranno automaticamente attribuiti come referral. Otterrai amici del 30% di premi minerali. ',
    Getlink: 'Ottieni il tuo link di riferimento',
    Getlinkdec: 'Si prega di connettersi prima al portafoglio e ottenere il collegamento di riferimento.',
    Invitefriends: 'Invita gli amici',
    Invitefriendsdecs: 'Condividi il tuo link di riferimento ai social network e traccia le prestazioni di ciascun mining DeFI nella pagina di riferimento.',
    Earnrewards: 'Guadagnare ricompense',
    Earnrewardsdec: 'Otterrai amici del 30% di premi minerali.',
     },
 mining: {
all: 'Tutte le catene',
 daypro: 'Tasso di rendimento giornaliero',
 cycle: ' Periodo defi ',
 balance: 'Equilibrio del portafoglio',
income: 'Resa giornaliera',
StakingNow:'Picchettare ora',
details: ' Dettagli ',
depositcycle: 'Flessibile',
Joinanonlineminer: 'Unisciti a un minatore online ',
Stakinginformation:'Informazioni sul picchettamento',
Annualrateofreturn:'Tasso di rendimento annuo',
Stakingbalance:'Saldo di puntata',
Stakingtotalincome:'Mettere in gioco il reddito totale',
Stakingdetails:'Dettagli di picchettamento',
chianclose:'Questa valuta non è stata ancora aperta',
MiningFees:'Commissioni minerarie insufficienti',
},account:{
  WalletID: 'Portafoglio ID',
  InviteFriends: 'Invita gli amici',
  DeFiMiningYieldBalance: 'Bilanciamento della resa di mining di DEFI',
  Assets: 'Risorse',

  YieldBalance: 'Equilibrio di resa',
  ReferralRewards: 'Premi di riferimento',
  DailyYieldDetails: 'Dettagli di resa giornaliera',
  Withdraw:'Ritirare',
  GetRewards :'Ottieni premi',
  WithdrawalHistory: 'Storia di ritiro',
  Assets: 'Risorse',
  Amount: 'Quantità',
  Status: 'Stato',
  Nomoredata: 'Niente più dati',
  complete: 'completare',
  publicchain: 'catena pubblica',
  time: 'tempo',
  Assetstip: 'risorse',
  Amounttip:'quantità',
  MinimumWithdrawal: 'Importo minimo di prelievo',
  InsufficientBalance: 'il tuo saldo è insufficiente',
  Waitinline: 'L\'invio ha avuto successo. A causa dell\'elevato numero di prelievi, attendere pazientemente',
  
 }

 }
