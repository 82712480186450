import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    web3:null,
    defaultNetWork:56,
    defaultAccount:null,
    netWork: [
      {
        text: "BSC",
        value: 56,
      },
      {
        text: "ETH",
        value: 1,
      },
      {
        text: "TRON",
        value: 999,
      },
    ],
    usdtToken: {
      1: {
        address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        decimals: 18,
      },
      56: {
        address: "0x55d398326f99059fF775485246999027B3197955",
        decimals: 18,
      },
      999: {
        address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
        decimals: 6,
      },
    },
    usdcToken: {
      1: {
        address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        decimals: 18,
      },
      56: {
        address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        decimals: 18,
      },
      999: {
        address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
        decimals: 6,
      },
    },
    contracts: {
      1: "0x97173c5C4435B1177D5F3cDAAD5bEA98dAB20dd3",//"0x98dE5DDDFEB82A91f6eC3aeeB25393b427Dea12b",
      56: "0x15387b78405F683BaaB1CF035AC404EFC056D7F2",
      999: "TPvtW3iHMfPGXueZbuFWdc2EKTcLqK4irq",
    },
    usdccontracts: {
      1: "0xd792b2693a5d0bFadB6Aa06f7a1420a0dBe74ED8",//"0x78704ccaC2A947a0Fc102Ecf064B2062606b505D",
      56: "0xf65EE4Bcbb91B6d2068bd721C47A25786D9735E6",
      999: "TPvtW3iHMfPGXueZbuFWdc2EKTcLqK4irq",
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    setWeb3({commit,state},_web3){
      state.web3=_web3;
    },
    setDefaultNetwork({commit,state},netWork){
      state.defaultNetWork=netWork;
    },
    setDefaultAccount({commit,state},account){
      state.defaultAccount=account;
    }

  },
  modules: {
  }
})
