export default{

menus: {
 home: ' Maison ',
 mining: ' DeFi Mining ',

 Referral: 'Référence', 
 account: 'Compte',
 ConnectWallet: 'Connecter',
 
 },
 home: {
  jion: 'Rejoignez DeFi Mining depuis un seul endroit',
  jiondefi: ' DeFi Hub est le moyen le plus simple de créer et de gérer l\'ensemble de votre minage DeFi à partir d\'un seul endroit.',
  jiondefit: 'Découvrez le monde de la finance décentralisée aujourd\'hui.',
  mining: 'minière DeFi', 
  Referral: ' Référence ', 
  bannertitle: 'Confidentialité et sécurité avant tout' , 
  bannerdesc: ' Avec DeFi Hub, vous avez toujours le contrôle. Nous n\'avons pas de comptes d\'utilisateurs et travaillons directement avec des portefeuilles Web3 tels que TronLink  , TrustWallet , SafePal, Coinbase.  Cela signifie que nous ne possédons pas vos clés privées et que nous ne pouvons jamais accéder à vos fonds. Nous ne croisons pas les données du portefeuille et nous ne partagerons jamais vos informations avec des tiers. '
  ,banneroepn:'Mondial, gratuit et ouvert à tous',
  bannerappnum:'milliers',
  bannerappopen:' des protocoles DeFi pris en charge',
  bannertrade:'1 milliard de dollars +',

  bannerapptrdl:'Volume échangé en février 2023',
  bannerusernum:'20 milliard de dollars +',
  bannerusernumlt:' Utilisateurs actifs mensuels en février 2023',
  bannerusernumlt:'Avez-vous des questions ?',
  Partner:'Approuvé par',
  toptips:'Devenez un validateur de transaction et contribuez à assurer l\'avenir de BSC ETH Tron',
toptipsleft:'Grâce au réseau BSC ETH Tron, vous pouvez devenir validateur de transaction.',
toptipsre:'Fournissez une vérification des transactions pour le réseau BSC ETH Tron et recevez des récompenses continues.',

  
  },Referral: {
      InviteFriends: 'Inviter des amis',
      EarnRewards : 'gagner des récompenses',
      InviteFriendsdec: 'Invitez vos amis à vous rejoindre et vous obtiendrez 30% de récompenses minières DeFi pour vos amis.',
      TotalReferrals: 'Références totales',

    ShareReferralLink: 'Partager le lien de parrainage',
    
    Copy: ' Copie ',
    YourReferrals: 'Vos références',
    User: 'Utilisateur',
    Assets: 'Actifs',
    Rewards: 'Récompenses',
    Nomoredata: 'Plus de données',
 
    Referralwork: 'Comment fonctionne le programme de parrainage ?',
    Referralworkdec: ' Le programme de parrainage vous permet de créer des liens de parrainage uniques qui invitent vos amis au minage DeFi. Si quelqu\'un clique sur le lien et le minage DeFi, il sera automatiquement attribué comme votre parrainage. vous obtiendrez 30% de récompenses minières DeFi pour vos amis.',
    Getlink: 'Obtenez votre lien de parrainage',
    Getlinkdec: 'Veuillez d\'abord vous connecter au portefeuille et obtenir un lien de parrainage.',
    Invitefriends: 'Inviter des amis',
    Invitefriendsdecs: 'Partagez votre lien de parrainage sur les réseaux sociaux et suivez les performances de chaque minage DeFi sur la page de parrainage.',
    Earnrewards: 'Gagner des récompenses',
    Earnrewardsdec: 'Vous obtiendrez pour vos amis 30% de récompenses minières DeFi.',
     },
 mining: {
all: 'Toutes les chaînes',
 daypro: 'Taux de rendement quotidien',
 cycle: 'Période DeFi',
 balance: 'Solde du portefeuille',
income: 'Rendement quotidien',
StakingNow:'Implanter maintenant',
details: 'Détails',
depositcycle: 'Flexible',
Joinanonlineminer: 'Rejoignez un mineur en ligne',
Stakinginformation:'Informations sur le jalonnement',
Annualrateofreturn:'Taux de rendement annuel',
Stakingbalance:'Équilibre de jalonnement',
Stakingtotalincome:'Revenu total de jalonnement',
Stakingdetails:'Détails de jalonnement',
chianclose:'Cette devise n\'a pas encore été ouverte',
MiningFees:'Frais miniers insuffisants',
},account:{
  WalletID: 'ID de portefeuille',
  InviteFriends: 'Inviter des amis',
  DeFiMiningYieldBalance: 'Balance de rendement de DeFi Mining',
  Assets: 'Actifs',

  YieldBalance: 'Solde de rendement',
  ReferralRewards: 'Récompenses de parrainage',
  DailyYieldDetails: 'Détails du rendement quotidien',
  
  WithdrawalHistory: 'Historique des retraits',
  Withdraw:'Retirer',
  GetRewards :'Obtenir des récompenses',
  Amount: 'Montant',
  Status: 'Statut',
  Nomoredata: 'Plus de données',
  complete: 'complet',
  
  publicchain: 'chaîne publique',
  time: 'temps',
  Assetstip: 'actifs',
  Amounttip:'quantité',
  MinimumWithdrawal: 'Montant minimum de retrait',
InsufficientBalance: 'votre solde est insuffisant',
Waitinline: 'La soumission est réussie. En raison du grand nombre de retraits, veuillez patienter',

 }

 }
