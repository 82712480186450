export default{

menus: {
 home: ' Home ',
 mining: ' DeFi Mining ',

 Referral: 'Referral', 
 account: 'Account',
 ConnectWallet: 'Connect Wallet',
 
 },
 home: {
  jion: 'Join in DeFi Mining from one place',
  jiondefi: ' DeFi Hub is the easiest way to build and manage your entire DeFi mining from one place.',
  jiondefit: 'Discover the world of decentralized finance today. ',
  mining: 'DeFi Mining', 
  Referral: ' Referral ', 
  bannertitle: 'Privacy and security first' , 
  bannerdesc: ' With DeFi Hub, you\'re in control, always. We don\'t have user accounts and instead work directly with Web3 wallets like TronLink  , TrustWallet , SafePal, Coinbase. This means we don\'t own your private keys and cannot ever access your funds. We don\'t cross-associate wallet data and we\'ll never share your information with third parties. '
  ,banneroepn:'Global, free and open to anyone',
  bannerappnum:'thousands',
  bannerappopen:' of DeFi protocols supported',
  bannertrade:' $1 Billion+ ',

  bannerapptrdl:'Volume traded as of February 2023',
  bannerusernum:'20 Billion+',
  bannerusernumlt:' Monthly active users as of February 2023',
  bannerusernumlt:'Have Any Questions ?',
  Partner:'Trusted by',
  toptips:' Become a transaction validator and help secure the future of BSC ETH Tron',
  toptipsleft:' Through the BSC ETH Tron network, you can become a transaction validator. ',
  toptipsre:' Provide transaction validation for the BSC ETH Tron network and earn continuous rewards. ',
  
  },Referral: {
      InviteFriends: 'Invite Friends',
      EarnRewards : 'Invite Friends ',
      InviteFriendsdec: 'Invite your friends to join and you will get you friends 30% DeFi mining rewards.',
      TotalReferrals: 'Total Referrals',

    ShareReferralLink: 'Share Referral Link',
    
    Copy: ' Copy ',
    YourReferrals: 'Your Referrals',
    User: 'User',
    Assets: 'Assets',
    Rewards: 'Rewards',
    Nomoredata: 'No more data',
 
    Referralwork: '  How does the  Referral Program work? ',
    Referralworkdec: ' The referral program allows you to create unique referral links that invite your friends to DeFi mining.If anyone clicks the link and DeFi mining,they\'ll be automatically attributed as your referral.  you will get you friends 30% DeFi mining rewards. ',
    Getlink: 'Get your referral link',
    Getlinkdec: 'Please connect to wallet first and get referral link.',
    Invitefriends: 'Invite friends',
    Invitefriendsdecs: 'Share your referral link to social networks and track the performance of each DeFi mining on the Referral page.',
    Earnrewards: 'Earn rewards',
    Earnrewardsdec: 'You will get you friends 30% DeFi mining rewards.',
     },
 mining: {
all: 'All Chains',
 daypro: 'Daily Yield Rate',
 cycle: ' DeFi Period ',
 balance: 'Wallet Balance',
income: 'Daily Yield',

details: ' Details ',
depositcycle: 'Flexible',
Joinanonlineminer: 'Join an online miner ',
StakingNow:'Staking Now',

Stakinginformation:'Staking Information',
Annualrateofreturn:'Annual rate of return',
Stakingbalance:'Staking Balance',
Stakingtotalincome:'Staking total income',
Stakingdetails:'Staking Details',
chianclose:'This currency has not been opened yet',
MiningFees:'Insufficient Mining Fees',
},account:{
  WalletID: 'Wallet ID',
  InviteFriends: 'Invite Friends',
  DeFiMiningYieldBalance: 'DeFi Mining Yield Balance',
 

  YieldBalance: 'Yield Balance',
  ReferralRewards: 'Referral Rewards',
  DailyYieldDetails: 'Daily Yield Details',
  Withdraw:'Withdraw',
  GetRewards :'Get Rewards',
  WithdrawalHistory: 'Withdrawal History',
  Assets: 'Assets',
  Amount: 'Amount',
  Status: 'Status',
  Nomoredata: 'No more data',
  complete: 'complete',
  TransactionID: 'Transaction ID:',


  Assetstip: 'Assets:',
  Amounttip: 'Amount:',

  publicchain: 'Public Chain:',
  time: 'Time:',
  MinimumWithdrawal: 'Minimum withdrawal amount ',
  InsufficientBalance: 'your balance is insufficient',
  Waitinline: 'The submission is successful. Due to the large number of withdrawals, please wait patiently',
 }

 }
