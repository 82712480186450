export default{

menus: {
 home: ' Lar ',
 mining: ' Defi mineração ',

 Referral: 'Referência', 
 account: 'Conta',
 ConnectWallet: 'Conecte a carteira',
 
 },
 home: {
  jion: 'Junte -se à Mineração Defi de um lugar',
  jiondefi: ' Defi Hub é a maneira mais fácil de construir e gerenciar toda a sua mineração de um lugar.',
  jiondefit: 'Descubra o mundo das finanças descentralizadas hoje. ',
  mining: 'Defi mineração', 
  Referral: ' Referência ', 
  bannertitle: 'Privacidade e segurança primeiro' , 
  bannerdesc: ' Com o Defi Hub, você está no controle, sempre. Não temos contas de usuário e, em vez disso, trabalhamos diretamente com carteiras Web3 como TronLink  , TrustWallet , SafePal, Coinbase.  Isso significa que não possuímos suas chaves privadas e nunca podemos acessar seus fundos. Não nós não compartilhamos dados da carteira cruzada e nunca compartilhamos suas informações com terceiros. '
  ,banneroepn:'Global, gratuito e aberto a qualquer um',
  bannerappnum:'milhares',
  bannerappopen:' de protocolos defi suportados',
  bannertrade:' US $ 1 bilhão+ ',

  bannerapptrdl:'Volume negociado a partir de fevereiro de 2023',
  bannerusernum:'20 bilhão+',
  bannerusernumlt:' Usuários ativos mensais em fevereiro de 2023',
  bannerusernumlt:'Tem alguma pergunta ?',
  Partner:'Confiável por',
  toptips:'Torne-se um validador de transações e ajude a garantir o futuro do BSC ETH Tron',
toptipsleft:'Através da rede BSC ETH Tron, você pode se tornar um validador de transações.',
toptipsre:'Forneça verificação de transação para a rede BSC ETH Tron e receba recompensas contínuas.',

  
  },Referral: {
      InviteFriends: 'Convide amigos',
      EarnRewards : 'Convide amigos ',
      InviteFriendsdec: 'Convide seus amigos para participar e você receberá seus amigos 30% defino recompensas de mineração.',
      TotalReferrals: 'Referências totais',

    ShareReferralLink: 'Compartilhe o link de referência',
    
    Copy: ' cópia de ',
    YourReferrals: 'Suas referências',
    User: 'Do utilizador',
    Assets: 'Ativos',
    Rewards: 'Recompensas',
    Nomoredata: 'Não há mais dados',
 
    Referralwork: '  Como funciona o programa de referência? ',
    Referralworkdec: ' O programa de referência permite criar links de referência exclusivos que convidam seus amigos a definir a mineração. Você vai dar a seus amigos 30% defino recompensas de mineração. ',
    Getlink: 'Obtenha seu link de referência',
    Getlinkdec: 'Por favor, conecte -se à carteira primeiro e obtenha o link de referência.',
    Invitefriends: 'Convide amigos',
    Invitefriendsdecs: 'Compartilhe seu link de referência para redes sociais e acompanhe o desempenho de cada Defi Minering na página de referência.',
    Earnrewards: 'Ganhe recompensas',
    Earnrewardsdec: 'Você vai dar a seus amigos 30% defino recompensas de mineração.',
     },
 mining: {
all: 'Todas as correntes',
 daypro: 'Taxa de rendimento diário',
 cycle: ' Defi Período ',
 balance: 'Balanço da carteira',
income: 'Rendimento diário',
StakingNow:'Apostar agora',
details: ' Detalhes ',
depositcycle: 'Flexível',
Joinanonlineminer: 'Junte -se a um mineiro online ',
Stakinginformation:'Informações de aposta',
Annualrateofreturn:'Taxa de retorno anual',
Stakingbalance:'Saldo de aposta',
Stakingtotalincome:'Rendimento total da aposta',
Stakingdetails:'Detalhes da aposta',
chianclose:'Esta moeda ainda não foi aberta',
MiningFees:'Taxas de mineração insuficientes',
},account:{
  WalletID: 'ID da carteira',
  InviteFriends: 'Convide amigos',
  DeFiMiningYieldBalance: 'Defi Balanço de rendimento de mineração',
  Assets: 'Ativos',

  YieldBalance: 'Balanço de rendimento',
  ReferralRewards: 'Recompensas de referência',
  DailyYieldDetails: 'Detalhes diários de rendimento',
  Withdraw:'Retirar',
  GetRewards :'Obter recompensas',
  WithdrawalHistory: 'História da retirada',
  Assets: 'Ativos',
  Amount: 'Quantia',
  Status: 'Status',
  Nomoredata: 'Não há mais dados',
  complete: 'completo',

  publicchain: 'cadeia pública',
  time: 'tempo',
  Assetstip: 'ativos',
  Amounttip:'quantidade',
  MinimumWithdrawal: 'Valor mínimo de retirada',
  InsufficientBalance: 'seu saldo é insuficiente',
  Waitinline: 'O envio foi bem-sucedido. Devido ao grande número de retiradas, aguarde pacientemente',
  
 }

 }
