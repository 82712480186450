<template>
  <div class="about">
    <el-row
      style="margin-left: 0px; margin-right: 0px; margin-top: 18px"
      :gutter="20"
    >
      <el-row :gutter="0" style="margin-top: 18px">
        <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1"> &nbsp; </el-col>
        <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
          <el-card class="box-card">
            <el-row :gutter="0">
              <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                <span @click="drawer = true">
                  <el-avatar
                    :size="50"
                    :src="circleUrl"
                    style="margin-top: 40px"
                  ></el-avatar
                ></span>
              </el-col>

              <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
                <h3
                  style="
                    padding-top: 0.5rem;
                    font-size: 1.3rem;
                    font-weight: bold;
                    color: rgba(50, 50, 50, 0.3);
                  "
                >
                  {{ $t("account.WalletID") }}
                </h3>
                <span
                  style="
                    max-width: 13rem;
                    font-size: 0.5rem;

                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                    font-weight: 500;
                    font-weight: bold;
                  "
                >
                  {{ defaultAccount }}
                </span>
              </el-col>
            </el-row>
          </el-card>

          <h3>{{ $t("account.DeFiMiningYieldBalance") }}</h3>

          <!--<el-alert title="Network Fee:1.00USDT" type="success" effect="dark">
          </el-alert>-->

          <el-descriptions title="" direction="vertical" :column="4" border>
            <el-descriptions-item :label="$t('account.Assets')"
              ><img
                style="
                  width: 1.5rem;
                  height: 1.5rem;
                  float: left;
                  display: block;
                  margin-top: 0.2rem;
                "
                src="../assets/USDT.png"
              />
              <div
                style="
                  float: left;
                  margin-left: 0.5rem;
                  line-height: 2rem;
                  font-size: 0.8rem;
                  font-weight: bold;
                  color: black;
                "
              >
                USDT
              </div></el-descriptions-item
            >
            <el-descriptions-item :label="$t('account.YieldBalance')"
              ><div
                style="
                  font-size: 0.9rem;
                  font-weight: bold;
                  color: black;
                  height: 2rem;
                  text-align: center;
                "
              >
                {{ Balance }}
              </div>
              <div
                v-if="Balance == 0"
                style="
                  height: 2rem;
                  line-height: 2rem;
                  width: 7rem;
                  text-align: center;
                  color: white;
                  font-weight: bold;
                  border-radius: 0.2rem;
                  background-color: #838280;
                "
              >
                {{ $t("account.Withdraw") }}
              </div>
              <div
                @click="opendilg('usdt')"
                v-if="Balance > 0"
                style="
                  height: 2rem;
                  line-height: 2rem;
                  width: 7rem;
                  text-align: center;
                  color: white;
                  font-weight: bold;
                  border-radius: 0.2rem;
                  background-color: #f0690f;
                "
              >
                {{ $t("account.Withdraw") }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              :label="$t('account.ReferralRewards')"
              :span="2"
              ><div
                style="
                  font-size: 0.9rem;
                  font-weight: bold;
                  color: black;
                  height: 2rem;
                  text-align: center;
                "
              >
                {{ Rewards }}
              </div>
              <div
                @click="dirminerals"
                style="
                  height: 2rem;
                  line-height: 2rem;
                  width: 7rem;
                  text-align: center;
                  color: white;
                  font-weight: bold;
                  border-radius: 0.2rem;
                  background-color: #f0690f;
                "
              >
                {{ $t("account.GetRewards") }}
              </div></el-descriptions-item
            >

            <el-descriptions-item
              ><img
                style="
                  width: 1.5rem;
                  height: 1.5rem;
                  float: left;
                  display: block;
                  margin-top: 0.2rem;
                "
                src="../assets/USDC.png"
              />
              <div
                style="
                  float: left;
                  margin-left: 0.5rem;
                  line-height: 2rem;
                  font-size: 0.8rem;
                  font-weight: bold;
                  color: black;
                "
              >
                USDC
              </div></el-descriptions-item
            >
            <el-descriptions-item
              ><div
                style="
                  font-size: 0.9rem;
                  font-weight: bold;
                  color: black;
                  height: 2rem;
                  text-align: center;
                "
              >
                {{ BalanceUsdc }}
              </div>
              <div
                v-if="BalanceUsdc == 0"
                style="
                  height: 2rem;
                  line-height: 2rem;
                  width: 7rem;
                  text-align: center;
                  color: white;
                  font-weight: bold;
                  border-radius: 0.2rem;
                  background-color: #838280;
                "
              >
                {{ $t("account.Withdraw") }}
              </div>
              <div
                @click="opendilg('usdc')"
                v-if="BalanceUsdc > 0"
                style="
                  height: 2rem;
                  line-height: 2rem;
                  width: 7rem;
                  text-align: center;
                  color: white;
                  font-weight: bold;
                  border-radius: 0.2rem;
                  background-color: #f0690f;
                "
              >
                {{ $t("account.Withdraw") }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item :span="2"
              ><div
                style="
                  font-size: 0.9rem;
                  font-weight: bold;
                  color: black;
                  height: 2rem;
                  text-align: center;
                "
              >
                {{ Rewards }}
              </div>
              <div
                @click="dirminerals"
                style="
                  height: 2rem;
                  line-height: 2rem;
                  width: 7rem;
                  text-align: center;
                  color: white;
                  font-weight: bold;
                  border-radius: 0.2rem;
                  background-color: #f0690f;
                "
              >
                {{ $t("account.GetRewards") }}
              </div></el-descriptions-item
            >
          </el-descriptions>
          <el-row stype="margin-top: 30px;">
            <el-row style="padding: 3px; font-weight: bold; margin-top: 30px">
              {{ $t("account.DailyYieldDetails") }}
            </el-row>

            <el-table
              :data="tableDatasl"
              stripe
              style="width: 100%; font-weight: bold"
              empty-text="--"
            >
              <el-table-column prop="Assets" :label="$t('account.Assets')">
                <template slot-scope="scope">
                  <span style="line-height: 35px">
                    <img
                      style="
                        width: 1.5rem;
                        height: 1.5rem;
                        float: left;
                        display: block;
                        margin-top: 0.2rem;
                      "
                      src="../assets/USDT.png"
                    />
                    {{ scope.row.Assets }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="amount" :label="$t('account.Amount')">
                <template slot-scope="scope">
                  <span v-if="scope.row.amount > 0" style="color: red">
                    {{ scope.row.amount }}</span
                  >
                  <span
                    v-if="scope.row.amount < 0"
                    style="color: rgb(1, 158, 35)"
                  >
                    {{ scope.row.amount }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="ctime" :label="$t('account.time')">
              </el-table-column>
              <el-table-column type="expand">
                <template slot-scope="props">
                  <el-form
                    label-position="left"
                    inline
                    class="demo-table-expand"
                  >
                    <el-form-item
                      :label="$t('account.publicchain')"
                      style="font-size: 12px"
                    >
                      <span v-if="props.row.ctype == 1">
                        <img
                          style="
                            width: 1.5rem;
                            height: 1.5rem;
                            float: left;
                            display: block;
                            margin-top: 0.2rem;
                          "
                          src="../assets/ETH.png"
                        />Ethereum</span
                      >
                      <span
                        v-if="props.row.ctype == 56"
                        style="line-height: 33px"
                      >
                        <img
                          style="
                            width: 1.5rem;
                            height: 1.5rem;
                            float: left;
                            display: block;
                            margin-top: 0.2rem;
                          "
                          src="../assets/BNB (Mainnet)o.7ae10f58.png"
                        />
                        BNB Smart Chain (BSC)</span
                      >
                      <span v-if="props.row.ctype == 999">
                        <img
                          style="
                            width: 1.5rem;
                            height: 1.5rem;
                            float: left;
                            display: block;
                            margin-top: 0.2rem;
                          "
                          src="../assets/ETH.png"
                        />TRON (TRX)</span
                      >
                    </el-form-item>
                    <el-form-item :label="$t('account.Assetstip')">
                      <span style="line-height: 33px">
                        <img
                          style="
                            width: 1.5rem;
                            height: 1.5rem;
                            float: left;
                            display: block;
                            margin-top: 0.2rem;
                          "
                          src="../assets/USDT.png"
                        />
                        {{ props.row.Assets }}</span
                      >
                    </el-form-item>
                    <el-form-item :label="$t('account.Amounttip')">
                      <span style="line-height: 40px"
                        ><font color="red">{{ props.row.amount }}</font></span
                      >
                    </el-form-item>
                    <el-form-item :label="$t('account.time')">
                      <span style="line-height: 40px">{{
                        props.row.ctime
                      }}</span>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
            </el-table>
          </el-row>

          <el-row stype="margin-top: 30px;">
            <el-row style="padding: 3px; font-weight: bold; margin-top: 30px">
              {{ $t("account.WithdrawalHistory") }}</el-row
            >
            <el-table
              :data="tableData"
              stripe
              style="width: 100%"
              empty-text="--"
            >
              <el-table-column prop="Assets" :label="$t('account.Assets')">
                <template slot-scope="scope">
                  <span style="line-height: 35px">
                    <img
                      style="
                        width: 1.5rem;
                        height: 1.5rem;
                        float: left;
                        display: block;
                        margin-top: 0.2rem;
                      "
                      src="../assets/USDT.png"
                    />
                    {{ scope.row.Assets }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="amount" :label="$t('account.Amount')">
              </el-table-column>
              <el-table-column prop="ctime" :label="$t('account.time')">
              </el-table-column>
            </el-table>
          </el-row>
        </el-col>

        <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1"> &nbsp; </el-col>
      </el-row>
    </el-row>
    <el-drawer title="" :visible.sync="drawer" :direction="direction">
      <span></span>
    </el-drawer>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
import vueQr from "vue-qr";

export default {
  components: { vueQr },
  /**
   *
   */
  data() {
    return {
      text: "111",
      tableData: null,
      tableDatasl: null,
      drawer: false,
      direction: "btt",
      Balance: 0,
      BalanceUsdc: 0,
      Rewards: 0,
      falg: true,

      circleUrl: require("../assets/3ea6beec64369c2642b92c6726f1epng.png"),
      qrCodeUrl: window.location.href, // 二维码的内容
      imageUrl: require("../assets/bhp.png"), // icon路径
    };
  },
  created() {
    //Promise
  },
  /**
   * 定义函数
   */
  methods: {
    async opendilg(currency) {
      this.$prompt(this.$t("account.Amount") + "", "", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      })
        .then(({ value }) => {
          this.withdraw(value, currency);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Cancel",
          });
        });
    },
    handleClose(done) {
      this.$confirm("")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    async gettablesl() {
      var that = this;
      let post = await axios({
        method: "post",
        url: "/api/sendapi/gettablesl",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          id: 1,
        },
      })
        .then(function (response) {
          console.log(response);
          if (response.data.code == 0) {
            that.tableDatasl = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    dirminerals() {
      this.$router.push({
        name: "invitation",
        query: {
          Invcode: this.$route.query.Invcode,
          aInvcode: this.$route.query.aInvcode,
        },
      });
    },
    async aftotal() {
      var that = this;
      let post = await axios({
        method: "post",
        url: "/api/sendapi/aftotal",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          id: 1,
        },
      })
        .then(function (response) {
          console.log(response);
          if (response.data.code == 0) {
            that.Rewards = response.data.data;
            console.log(response);
          } else {
            that.Rewards = 0;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async gettablewh() {
      var that = this;
      let post = await axios({
        method: "post",
        url: "/api/sendapi/gettablewh",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          id: 1,
        },
      })
        .then(function (response) {
          console.log(response);
          if (response.data.code == 0) {
            that.tableData = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async copy() {
      {
        this.$copyText(this.qrCodeUrl).then(
          (e) => {
            this.$message({
              message: "copy successfully",
              type: "success",
            });
          },
          (e) => {
            this.$message({
              message: "replication failed",
              type: "warning",
            });
            console.log("replication failed", e);
          }
        );
      }
    },
    async checklogin() {
      var that = this;
      let post = await axios({
        method: "post",
        url: "/api/sendapi/checklogin",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          id: 1,
        },
      })
        .then(function (response) {
          console.log(response);
          if (response.data.code == 0) {
            that.qrCodeUrl =
              "https://" +
              window.location.hostname +
              "/#/" +
              response.data.info;
            console.log(that.qrCodeUrl);
            that.Balance = response.data.usdtbl;
            that.BalanceUsdc = response.data.usdc;
            console.log(that);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async withdraw(mny, currency) {
      var that = this;
      if (this.defaultNetWork == 999 || this.defaultNetWork == 56) {
        if (mny < 1) {
          that.$message({
            type: "error",
            message: that.$t("account.MinimumWithdrawal") + " > 1 USDT",
          });
          return;
        }
      } else if (this.defaultNetWork == 1) {
        if (mny < 10) {
          that.$message({
            type: "error",
            message: that.$t("account.MinimumWithdrawal") + " > 10 USDT",
          });
          return;
        }
      }

      let post = await axios({
        method: "post",
        url: "/api/sendapi/withdraw",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          mny: mny,
          currency: currency,
        },
      })
        .then(function (response) {
          console.log(response);
          if (response.data.code == 0) {
            that.$message({
              type: "success",
              message: that.$t("account.Waitinline"),
            });
            that.checklogin();
          } else if (response.data.code == 2) {
            that.$message({
              type: "error",
              message: that.$t("account.InsufficientBalance"),
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  mounted: function () {
    this.checklogin();
    this.gettablesl();
    this.gettablewh();
    this.aftotal();
  },
  watch: {
    defaultAccount: {
      handler(newaccout, oldaccount) {
        this.checklogin();
        this.gettablesl();
        this.gettablewh();
      },
    },
    defaultNetWork: {
      handler(newNetWork, oldNetWork) {
        console.log(newNetWork);
        this.checklogin();
        this.gettablesl();
        this.gettablewh();
      },
    },
  },
  computed: {
    ...mapState([
      "usdtToken",
      "usdcToken",
      "contracts",
      "usdccontracts",
      "web3",
      "defaultNetWork",
      "defaultAccount",
      "checkLoginState",
    ]),
  },
};
</script>
<style scoped>
.topbg {
  background: url(../assets/referralbg.49cb867c.png) no-repeat;
  height: 32.5rem;
  width: 100%;

  background-size: 100% 100%;
}
@media (max-width: 720px) {
  .el-message-box {
    width: 320px !important;
  }
}
</style>
<style>
@media (max-width: 720px) {
  .el-message-box {
    width: 320px !important;
  }
}
</style>
<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 95%;
}
</style>