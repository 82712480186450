import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 从语言包文件中导入语言包对象
import zh from './zh'
import en from './en'
import de from './de'
import es from './es'
import fr from './fr'
import hi from './hi'
import id from './id'
import it from './it'
import ja from './ja'
import ko from './ko'
import ph from './ph'
import pt from './pt'
import ru from './ru'
import th from './th'
import tr from './tr'
import uk from './uk'
import vi from './vi'
Vue.use(VueI18n)

const messages = {
  zh,
  en,
  de,
es,
fr,
hi,
id,
it,
ja,
ko,
ph,
pt,
ru,
th,
tr,
uk,
vi,

}
const i18n = new VueI18n({
  messages,
  locale: 'en'
})
export default i18n

