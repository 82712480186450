
<template>
  <el-container
    style="padding-left: 0px; padding-right: 0px; min-height: 1500px;height: auto;"
  >
    <el-main class="main" style="padding-top: 0px; overflow: visible">
      <el-row :gutter="20" class="mainbanner">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          style="
            font-weight: 500 !important  ;
            font-size: 1.5rem !important;
            font-weight: 400;
            letter-spacing: normal !important;
            overflow-wrap: break-word !important;
            word-break: break-word !important;
            margin-top: 24px;
            text-align:center;
          "
        > {{ $t("home.toptips") }}</el-col
        >

        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="12"
          :xl="12"
          style="text-align: center; margin-top: 24px"
        >
          <img src="../assets/icon-ux.19ba2f9a.svg" height="60px" />
          <p></p>
          {{ $t("home.toptipsleft") }}
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="12"
          :xl="12"
          style="text-align: center; margin-top: 24px"
        >
          <img
            src="../assets/icon-eth-compatibility.e1283680.svg"
            height="60px"
          />
          <p></p>{{ $t("home.toptipsre") }}
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          style="text-align: center ;  margin-bottom: 20px;
    margin-top: 20px;
"
        >
          <el-button
            @click="dirminerals"
            style="
              background-image: linear-gradient(to right, #f8962f, #dd5900);
              font-size: 18px;
              width: 180px;
              color: white;
            "
          >
          {{ $t("home.mining") }} <i class="el-icon-right el-icon--right"></i
          ></el-button>
        </el-col>
      </el-row>

      <!--<el-row :gutter="24">
        <el-col :span="24" class="jionBG">
          <h1 class="h1tite">從一處加入 DeFi 挖礦</h1>
          <div class="desctitle">
            DeFi ad 是一體化構建和管理整個 DeFi 挖礦的最簡單方法。
          </div>
          <div class="descnode">立即探索去中心化金融的世界。</div>
          <div>
            <el-row style="padding: 20px">
              <el-button
                @click="dirminerals"
                style="
                  background-image: linear-gradient(to right, #f8962f, #dd5900);
                  font-size: 18px;
                  width: 180px;
                  color: white;
                "
              >
                DeFi 挖礦 <i class="el-icon-right el-icon--right"></i
              ></el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>-->
      <el-row :gutter="24" style="align-items: center; background: #fff">
        <el-col :xs="0" :sm="0" :md="1" :lg="0" :xl="1">&nbsp;</el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="12"
          :xl="12"
          style="padding-top: 10px"
          ><el-card class="box-card" style="height: 30rem">
            <el-row class="box_title"> {{ $t("home.bannertitle") }}  </el-row>
            <el-row>
              {{ $t("home.bannerdesc") }}
            </el-row>
            <el-row :gutter="24" style="margin-top: 35px">
              <el-col :span="5">
                <div
                  style="
                    border-radius: 50%;
                    border-radius: 50%;
                    background-color: #fff;
                    height: 80px;
                    width: 80px;
                    align-items: center;
                    text-align: center;
                    box-shadow: 0.25rem 0.5rem 0.9rem;
                  "
                >
                  <img
                    data-v-c290a6d4=""
                    src="../assets/save.png"
                    style="height: 60px; margin-top: 5px"
                  />
                </div>
              </el-col>

              <el-col :span="5">
                <div
                  style="
                    border-radius: 50%;
                    border-radius: 50%;
                    background-color: #fff;
                    height: 80px;
                    width: 80px;
                    align-items: center;
                    text-align: center;
                    box-shadow: 0.25rem 0.5rem 0.9rem;
                  "
                >
                  <img
                    src="../assets/trust.png"
                    style="height: 60px; margin-top: 5px"
                  /></div
              ></el-col>
              <el-col :span="5">
                <div
                  style="
                    border-radius: 50%;
                    border-radius: 50%;
                    background-color: #fff;
                    height: 80px;
                    width: 80px;
                    align-items: center;
                    text-align: center;
                    box-shadow: 0.25rem 0.5rem 0.9rem;
                  "
                >
                  <img
                  src="../assets/tron.png"
                    style="height: 60px; margin-top: 5px"
                  /></div
              ></el-col>
              <el-col :span="5">
                <div
                  style="
                    border-radius: 50%;
                    border-radius: 50%;
                    background-color: #fff;
                    height: 80px;
                    width: 80px;
                    align-items: center;
                    text-align: center;
                    box-shadow: 0.25rem 0.5rem 0.9rem;
                  "
                >
                  <img
                    src="../assets/safepal.png"
                    style="height: 60px; margin-top: 5px"
                  /></div
              ></el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="12"
          :xl="12"
          style="padding-top: 10px"
          ><el-card class="box-card" style="height: 30rem">
            <h1 class="rbox_title"> {{ $t("home.banneroepn") }}</h1>
            <h1 class="rbox_title"> {{ $t("home.bannerappnum") }}</h1>
            {{ $t("home.bannerappopen") }}
            <h1 class="rbox_title"> {{ $t("home.bannertrade") }}</h1>
            {{ $t("home.bannerapptrdl") }}
            <h1 class="rbox_title"> {{ $t("home.bannerusernum") }}</h1>
            {{ $t("home.bannerusernumlt") }}
          </el-card></el-col
        >

        <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">&nbsp;</el-col>
      </el-row>
      <el-row :gutter="24" style="align-items: center; background: #fff">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          style="padding-top: 10px"
        >
          <el-timeline>
            <el-timeline-item timestamp="2017/4/12" placement="top">
            
                <h4><img style="float: left;" src="../assets/MATIC.png"></img>
              2017 MATIC founded</h4>
               
            </el-timeline-item>
            <el-timeline-item timestamp="2019/4/3" placement="top">
              
                <h4><img style="float: left;" src="../assets/bnb.png"></img>
              2019 IEO on Binance</h4>
              
            </el-timeline-item>
            <el-timeline-item timestamp="JUN 2020" placement="top">
             
                <h4><img style="float: left;" src="../assets/Node.png">
                
                  Mainnet Launch • POS Chain • Plasma Chain</h4>
              
            </el-timeline-item>
            <el-timeline-item timestamp="END 2020" placement="top">
           
                <h4><img style="float: left;" src="../assets/game.png">
                
                FEB  2020 Top Games onboarded</h4>
              
                <p>
                
                  
              </p>
            </el-timeline-item>
            <el-timeline-item timestamp="FEB 2021" placement="top">
             
                <h4><img style="float: left;" src="../assets/Polygon.png"></img>Rebranded Matic to Polygon</h4>
                <p>
                
                  
                </p>
             
            </el-timeline-item>
            <el-timeline-item timestamp="APR 2021" placement="top">
             
             <h4><img style="float: left;" src="../assets/Major.png"></img>Major DeFi Protocols onboarded</h4>
             <p>
             
               
             </p>
          
         </el-timeline-item>     
         
         <el-timeline-item timestamp="MAY 2021" placement="top">
             
             <h4><img style="float: left;" src="../assets/mcc.png"></img>Mark Cuban invests market cap exceeds $10B</h4>
             <p>
             
               
             </p>
          
         </el-timeline-item>

         <el-timeline-item timestamp="JUN - JUL 2021" placement="top">
             
             <h4><img style="float: left;" src="../assets/Node.png"></img>
Polygon Edge Launch • Polygon Studio Launch</h4>
             <p>
             
               
             </p>
          
         </el-timeline-item>


         
         <el-timeline-item timestamp="JUN - JUL 2021" placement="top">
             
             <h4><img style="float: left;" src="../assets/Node.png"></img>Polygon Avail Launch • Rollups - Optimistic, ZK • Stand-alone, Secure Chains</h4>
             <p>
             
               
             </p>
          
         </el-timeline-item>


         <el-timeline-item timestamp="NOV 2021" placement="top">
             
             <h4><img style="float: left;" src="../assets/Node.png"></img>Launch of zk-STARK based scaling solution Polygon Miden</h4>
             <p>
             
               
             </p>
          
         </el-timeline-item>


         <el-timeline-item timestamp="DEC 2021" placement="top">
             
             <h4><img style="float: left;" src="../assets/Node.png"></img>Polygon Node Launch, Uniswap goes live on Polygon</h4>
             <p>
             
               
             </p>
          
         </el-timeline-item>

         <el-timeline-item timestamp="JAN 2022" placement="top">
             
             <h4><img style="float: left;" src="../assets/Polygon.png"></img>Polygon Node's validators exceeded 500,000</h4>
             <p>
             
               
             </p>
          
         </el-timeline-item>
         

         <el-timeline-item timestamp="FEB 2022" placement="top">
             
             <h4><img style="float: left;" src="../assets/Polygon.png"></img>
Polygon Raises $450,000,000 From Sequoia Capital India, SoftBank, Galaxy, Tiger, Republic Capital</h4>
             <p>
             
               
             </p>
          
         </el-timeline-item>

         <el-timeline-item timestamp="MARCH 2022" placement="top">
             
             <h4><img style="float: left;" src="../assets/ID.png"></img>Polygon ID Launch - a zero-knowledge identity for web3</h4>
             <p>
             
               
             </p>
          
         </el-timeline-item>


         <el-timeline-item timestamp="APRIL 2022" placement="top">
             
             <h4><img style="float: left;" src="../assets/goes.png"></img>Polygon goes carbon-neutral</h4>
             <p>
             
               
             </p>
          
         </el-timeline-item>


         <el-timeline-item timestamp="NOV 2022" placement="top">
             
             <h4><img style="float: left;" src="../assets/goes.png"></img>Polygon Node network upgrade completed, staking has been introduced to some enterprises and high-quality users</h4>
             <p>
             
               
             </p>
          
         </el-timeline-item>
          </el-timeline>

  
        </el-col>
      </el-row>
      <el-row :gutter="24" style="align-items: center; background: #fff">
        <el-col :xs="0" :sm="0" :md="1" :lg="0" :xl="1">&nbsp;</el-col>

        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="12"
          :xl="12"
          style="padding-top: 10px"
        >
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>  {{ $t("home.bannerusernumlt") }}</span>
              <el-button style="float: right; padding: 3px 0" type="text"
                >?</el-button
              >
            </div>

            <el-collapse  accordion>
              <el-collapse-item title="What is DeFi?" name="1">
                <div>
                  DeFi (or “decentralized finance”) is an umbrella term for
                  financial services on public blockchains, primarily Ethereum.
                  With DeFi, you can do most of the things that banks support —
                  earn interest, borrow, lend, buy insurance, trade derivatives,
                  trade assets, and more — but it’s faster and doesn’t require
                  paperwork or a third party. As with crypto generally, DeFi is
                  global, peer-to-peer (meaning directly between two people, not
                  routed through a centralized system), pseudonymous, and open
                  to all.
                </div>
                <div></div>
              </el-collapse-item>
              <el-collapse-item title="What are the benefits?" name="2">
                <div>
                  <p>
                    Open: You don’t need to apply for anything or “open” an
                    account. You just get access by creating a wallet.
                  </p>
                  <p>
                    Pseudonymous: You don’t need to provide your name, email
                    address, or any personal information.
                  </p>
                  <p>
                    Flexible: You can move your assets anywhere at any time,
                    without asking for permission, waiting for long transfers to
                    finish, and paying expensive fees.
                  </p>
                  <p>
                    Transparent: Everyone involved can see the full set of
                    transactions (private corporations rarely grant that kind of
                    transparency)
                  </p>
                </div>
                <div></div>
              </el-collapse-item>
              <el-collapse-item title="How does DeFi Work?" name="3">
                <div>
                  <p data-v-c290a6d4="">
                    Users typically engage with DeFi via software called dapps
                    (“decentralized apps”), most of which currently run on the
                    Ethereum blockchain. Unlike a conventional bank, there is no
                    application to fill out or account to open.
                  </p>
                  <p data-v-c290a6d4="">
                    Here are some of the ways people are engaging with DeFi
                    today:
                  </p>
                  <p data-v-c290a6d4="">
                    Lending: Lend out your crypto and earn interest and rewards
                    every minute - not once per month.
                  </p>
                  <p data-v-c290a6d4="">
                    Getting a loan: Obtain a loan instantly without filling in
                    paperwork, including extremely short-term “flash loans” that
                    traditional financial institutions don’t offer.
                  </p>
                  <p data-v-c290a6d4="">
                    Trading: Make peer-to-peer trades of certain crypto assets —
                    as if you could buy and sell stocks without any kind of
                    brokerage.
                  </p>
                  <p data-v-c290a6d4="">
                    Saving for the future: Put some of your crypto into savings
                    account alternatives and earn better interest rates than
                    you’d typically get from a bank.
                  </p>
                  <p data-v-c290a6d4="">
                    Buying derivatives: Make long or short bets on certain
                    assets. Think of these as the crypto version of stock
                    options or futures contracts.
                  </p>
                </div>
              </el-collapse-item>
              <el-collapse-item title="What is staking?" name="4">
                <div>
                  Like a lot of things in crypto, staking can be a complicated
                  idea or a simple one depending on how many levels of
                  understanding you want to unlock. For a lot of crypto users,
                  knowing that staking is a way of earning rewards while holding
                  onto certain cryptocurrencies is the key takeaway. But even if
                  you’re just looking to earn some staking rewards, it’s useful
                  to understand at least a little bit about how and why it works
                  the way it does.
                </div>
                <div></div>
              </el-collapse-item>
              <el-collapse-item title="How does staking work?" name="5">
                <div>
                  If a cryptocurrency you own allows staking — current options
                  include Ethereum, BNB Smart Chain(BSC),ETH,
                  TRON(TRX) and others — you can “stake” some of your holdings
                  and earn a reward over time. The reason your crypto earns
                  rewards while staked is because the blockchain puts it to
                  work. Cryptocurrencies that allow staking use a “consensus
                  mechanism” called Proof of Stake, which is the way they ensure
                  that all transactions are verified and secured without a bank
                  or payment processor in the middle. Your crypto, if you choose
                  to stake it, becomes part of that process.
                </div>
                <div></div>
              </el-collapse-item>
              <el-collapse-item title="How does staking work?" name="6">
                <div>
                  If a cryptocurrency you own allows staking — current options
                  include Ethereum, BNB Smart Chain(BSC), ETH,
                  TRON(TRX) and others — you can “stake” some of your holdings
                  and earn a reward over time. The reason your crypto earns
                  rewards while staked is because the blockchain puts it to
                  work. Cryptocurrencies that allow staking use a “consensus
                  mechanism” called Proof of Stake, which is the way they ensure
                  that all transactions are verified and secured without a bank
                  or payment processor in the middle. Your crypto, if you choose
                  to stake it, becomes part of that process.
                </div>
                <div></div>
              </el-collapse-item>
              <el-collapse-item
                title="What are the advantages of staking?"
                name="7"
              >
                <div>
                  <p data-v-c290a6d4="">
                    Many long-term crypto holders look at staking as a way of
                    making their assets work for them by generating rewards,
                    rather than collecting dust in their crypto wallets.
                  </p>
                  <p data-v-c290a6d4="">
                    Staking is also a way to contribute to the security and
                    efficiency of the blockchain projects you support. By
                    staking some of your funds, you make the blockchain more
                    resistant to attacks and strengthen its ability to process
                    transactions.
                  </p>
                </div>
                <div></div>
              </el-collapse-item>
            </el-collapse>
          </el-card>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="8"
          :xl="8"
          style="padding-top: 10px"
        >
          <img data-v-c290a6d4="" src="../assets/questions.png" width="100%" />
        </el-col>

        <el-col :xs="1" :sm="1" :md="1" :lg="2" :xl="2">&nbsp;</el-col>
      </el-row>
      <el-row :gutter="24" style="padding-top: 10px">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="2"
          style="width: 100%; text-align: center"
          ><h3>{{ $t("home.Partner") }}</h3></el-col
        >
      </el-row>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2">&nbsp;</el-col>
        <el-col
          :xs="12"
          :sm="12"
          :md="4"
          :lg="4"
          :xl="4"
          style="  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  height: 3rem;
  margin-bottom: 1.5rem;"
        >
          <img
            data-v-c290a6d4=""
            src="../assets/coinfoot1.ad1d6c46.jpg"
            width="100%"
        /></el-col>
        <el-col
          :xs="12"
          :sm="12"
          :md="4"
          :lg="4"
          :xl="4"
          style="  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  height: 3rem;
  margin-bottom: 1.5rem;"
        >
          <img
            data-v-c290a6d4=""
            src="../assets/coinfoot2.184846d0.jpg"
            width="100%"
        /></el-col>
        <el-col
          :xs="12"
          :sm="12"
          :md="4"
          :lg="4"
          :xl="4"
          style="  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  height: 3rem;
  margin-bottom: 1.5rem;"
        >
          <img
            data-v-c290a6d4=""
            src="../assets/coinfoot3.e0dfa075.jpg"
            width="100%"
        /></el-col>
        <el-col
          :xs="12"
          :sm="12"
          :md="4"
          :lg="4"
          :xl="4"
          style="  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  height: 3rem;
  margin-bottom: 1.5rem;"
        >
          <img
            data-v-c290a6d4=""
            src="../assets/coinfoot4.d1957f81.jpg"
            width="100%"
        /></el-col>
        <el-col
          :xs="12"
          :sm="12"
          :md="4"
          :lg="4"
          :xl="4"
          style="  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  height: 3rem;
  margin-bottom: 1.5rem;"
        >
          <img
            data-v-c290a6d4=""
            src="../assets/coinfoot5.00444c5c.png"
            width="100%"
        /></el-col>
        <el-col
          :xs="0.1"
          :sm="0.1"
          :md="2"
          :lg="2"
          :xl="2"
          class="hidden-md-and-down"
          style="text-align: center; height: 3rem; margin-bottom: 3rem"
          >&nbsp;</el-col
        >

        <el-col
          :xs="0.1"
          :sm="0.1"
          :md="2"
          :lg="2"
          :xl="2"
          class="hidden-md-and-down"
          style="text-align: center; height: 3rem; margin-bottom: 3rem"
          >&nbsp;</el-col
        >
        <el-col
          :xs="12"
          :sm="12"
          :md="4"
          :lg="4"
          :xl="4"
          style="  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  height: 3rem;
  margin-bottom: 1.5rem;"
        >
          <img
            data-v-c290a6d4=""
            src="../assets/coinfoot6.8fdaf7f8.svg"
            width="100%"
        /></el-col>
        <el-col
          :xs="12"
          :sm="12"
          :md="4"
          :lg="4"
          :xl="4"
          style="  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  height: 3rem;
  margin-bottom: 1.5rem;"
        >
          <img
            data-v-c290a6d4=""
            src="../assets/coinfoot7.37f8d022.png"
            width="100%"
        /></el-col>
        <el-col
          :xs="12"
          :sm="12"
          :md="4"
          :lg="4"
          :xl="4"
          style="  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  height: 3rem;
  margin-bottom: 1.5rem;"
        >
          <img
            data-v-c290a6d4=""
            src="../assets/coinfoot8.73a52885.jpg"
            width="100%"
        /></el-col>
        <el-col
          :xs="12"
          :sm="12"
          :md="4"
          :lg="4"
          :xl="4"
          style="  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  height: 3rem;
  margin-bottom: 1.5rem;"
        >
          <img
            data-v-c290a6d4=""
            src="../assets/coinfoot9.c0721ca0.jpg"
            width="100%"
        /></el-col>
        <el-col
          :xs="12"
          :sm="12"
          :md="4"
          :lg="4"
          :xl="4"
          style="  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  height: 3rem;
  margin-bottom: 1.5rem;"
        >
          <img
            data-v-c290a6d4=""
            src="../assets/coinfoot10.ef46a53d.jpg"
            width="100%"
        /></el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2">&nbsp;</el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  /**
   * 定义组件数据变量
   */
  data() {
    return {
      text: "111",
      falg: true,
      activeIndex: "1",
      activeIndex2: "1",
    };
  },
  created() {
    //页面加载完成后自动执行

    //Promise
    this.begin();
  },
  /**
   * 定义函数
   */
  methods: {
    async begin() {
      {
        /**this.init(); */
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    dirminerals() {
      this.$router.push({
        name: "minerals",
        query: {
          Invcode: this.$route.query.Invcode,
          aInvcode: this.$route.query.aInvcode,
        },
      });
    },

    handleChange(val) {
      console.log(val);
    },
    async init() {
      console.log(aaa, "aaa");
    },

    a() {
      this.text = "222";
      this.falg = !this.falg;
    },
    b() {},
  },
};
</script>
<style scoped>
.mainbanner {
  transform: unset;
  background: radial-gradient(
      66.32% 66.32% at 54.13% 113.95%,
      rgb(236, 227, 220) 0,
      rgba(236, 227, 220, 0) 100%
    ),
    linear-gradient(
      211.99deg,
      rgb(245 230 231 / 20%) -4.17%,
      rgba(131, 128, 127, 0) 68.7%
    ),
    radial-gradient(
      100% 100% at 27.65% 0,
      rgb(241 190 157) 0,
      rgba(138, 135, 133, 0) 100%
    ) !important;
  background-blend-mode: multiply, normal;
}

.container {
  border: 1px solid #eee;
  margin: -10px;
  padding: 10px;
}
.container .item {
  margin: 10px;
}
.el-row {
  margin-left: 0px;
  margin-right: 0px;
}
.box_title {
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
  height: 3rem;
  margin-bottom: 1rem;
}
.boximg {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  height: 3rem;
  margin-bottom: 1.5rem;
}
.rbox_title {
  margin: 0 0 2rem;
  font-size: 1.7rem;
  line-height: 1.25;
  font-weight: 700;
}
.box_title {
  margin: 0 0 2rem;
  font-size: 1.7rem;
  line-height: 1.25;
  font-weight: 700;
}
</style>


