
<template>
  <div id="app">
    <defi-header></defi-header>
    <router-view></router-view>
    <defi-footer></defi-footer>
  </div>
</template>
<script>
import axios from "axios";

import home from "./views/HomeView.vue";
import DefiHeader from "./components/Defiheader.vue";
import DefiFooter from "./components/Defifooter.vue";
export default {
  components: { home, DefiHeader, DefiFooter },
  /**
   * 定义组件数据变量
   */
  data() {
    return {
      text: "111",
      falg: true,

      tableData: null,
    };
  },
  created() {
    //页面加载完成后自动执行

    //Promise
    this.begin();
  },
  /**
   * 定义函数
   */
  methods: {
    async begin() {
      {
        /**this.init(); */
      }
    },
    onConsole() {
      console.log(this.$refs);
      this.$refs.mybody.log();
    },

    handleChange(val) {
      console.log(val);
    },
    async init() {
      let aaa = await axios.get(
        "/api/s?ie=utf-8&newi=1&mod=1&isbd=1&isid=57E9B1B5A2145423&wd=%E6%98%AF%E4%BB%80%E4%B9%88&rsv_spt=1&rsv_iqid=0xdf1bf18f0001f083&issp=1&f=8&rsv_bp=1&rsv_idx=2&ie=utf-8&tn=baiduhome_pg&rsv_enter=0&rsv_dl=tb&rsv_sug3=12&rsv_sug1=3&rsv_sug7=101&rsv_btype=i&inputT=5937&rsv_sug4=7358&rsv_sid=36552_38643_38831_39023_38943_38876_38959_38956_39009_39038_38973_38806_38986_39088_38637_26350_39042_39092_39100&_ss=1&clist=&hsug=&f4s=1&csor=3&_cr1=32479"
      );
      console.log(aaa, "aaa");
    },

    a() {
      this.text = "222";
      this.falg = !this.falg;
    },
    b() {},
  },
};
</script>

<style>
.menuBG {
  padding: 0px;
}
#app > .main {
  width: 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.el-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
}
.main {
  padding-bottom: 100px;
}
.jionBG {
  background: url(./assets/banner-bg.2967d481.jpg) no-repeat;
  height: 43.75rem;
  width: 100%;
  background-size: 100% 100%;
  text-align: center;
}

.el-button--success.is-active,
.el-button--success:active {
  border-color: #70b7b7;
  background-image: linear-gradient(to right, #dd5900, #f8962f);
  color: #fff;
}
.el-button--success {
  color: #fff;
  background-color: #0c0e0b;
  border-color: #0f0f0e;
}
.homeWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.main_container {
  height: 100%;
}
.h1tite {
  font-weight: bold;
  margin: auto;
  max-width: 48rem;
  margin-top: 11rem;
  font-size: 2.815rem;
  text-align: center;
  line-height: 1.25;
}

.desctitle {
  padding-top: 4rem;
  text-align: center;
  max-width: 60rem;
  margin: auto;
  font-size: 1.2rem;
  font-weight: 400;
  opacity: 0.5;
  color: #183185;
}
.descnode {
  text-align: center;
  max-width: 60rem;
  margin: auto;
  font-size: 1.2rem;
  font-weight: 400;
  opacity: 0.5;
  color: #183185;
}
html,
body,
#app,
.el-container,
.main {
  /*设置内部填充为0，几个布局元素之间没有间距*/
  padding: 0px;
  /*外部间距也是如此设置*/
  margin: 0px;
  /*统一设置高度为100%*/
  height: 100%;
}
.box_title {
  margin: 0 0 2rem;
  font-size: 3rem;
  line-height: 1.25;
  font-weight: 700;
}
.rbox_title {
  margin: 1rem 0 2rem;
  font-size: 2.5rem;
  line-height: 1.25;
  font-weight: 700;
}
.el-container {
  min-height: 100vh;
}
.el-tabs__item.is-active {
  color: #f8f9fb !important;
  background-image: linear-gradient(to right, #dd5900, #f8962f);
}
.main /deep/ .el-tabs__item.is-active {
  color: #000000 !important;
}
</style>