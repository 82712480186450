export default{

menus: {
 home: ' Rumah ',
 mining: ' Defi Mining ',

 Referral: 'Rujukan', 
 account: 'Akun',
 ConnectWallet: 'Hubungkan Dompet',
 
 },
 home: {
  jion: 'Bergabunglah dalam penambangan defi dari satu tempat',
  jiondefi: ' Defi Hub adalah cara termudah untuk membangun dan mengelola seluruh penambangan Anda dari satu tempat.',
  jiondefit: 'Temukan dunia keuangan terdesentralisasi saat ini. ',
  mining: 'DeFi Mining', 
  Referral: ' Rujukan ', 
  bannertitle: 'Privasi dan Keamanan Pertama' , 
  bannerdesc: ' Dengan Defi Hub, Anda selalu dalam kendali, selalu. Kami tidak memiliki akun pengguna dan sebaliknya bekerja secara langsung dengan dompet Web3 seperti TronLink  , TrustWallet , SafePal, Coinbase.  Ini berarti kami tidak memiliki kunci pribadi Anda dan tidak dapat mengakses dana Anda. Kami tidak memiliki data dompet silang dan kami tidak akan pernah membagikan informasi Anda dengan pihak ketiga. '
  ,banneroepn:'Global, gratis dan terbuka untuk siapa saja',
  bannerappnum:'ribuan',
  bannerappopen:' protokol defi didukung',
  bannertrade:' $ 1 miliar+ ',

  bannerapptrdl:'Volume diperdagangkan pada Februari 2023',
  bannerusernum:'20  miliar+',
  bannerusernumlt:' Pengguna aktif bulanan pada Februari 2023',
  bannerusernumlt:'Ada pertanyaan ?',
  Partner:'Dipercaya oleh',
  toptips:'Menjadi validator transaksi dan membantu mengamankan masa depan BSC ETH Tron',
  toptipsleft:'Melalui jaringan BSC ETH Tron, Anda bisa menjadi validator transaksi.',
  toptipsre:'Berikan verifikasi transaksi untuk jaringan BSC ETH Tron dan dapatkan hadiah terus menerus.',
  
  },Referral: {
      InviteFriends: 'Mengundang teman-teman',
      EarnRewards : 'Mengundang teman-teman ',
      InviteFriendsdec: 'Undang teman Anda untuk bergabung dan Anda akan mendapatkan Anda teman penambangan DEFI 30%.',
      TotalReferrals: 'Total Referensi',

    ShareReferralLink: 'Bagikan tautan rujukan',
    
    Copy: ' Menyalin ',
    YourReferrals: 'Referensi Anda',
    User: 'Pengguna',
    Assets: 'Aktiva',
    Rewards: 'Hadiah',
    Nomoredata: 'Tidak ada data lagi',
 
    Referralwork: '  Bagaimana cara kerja program rujukan? ',
    Referralworkdec: ' Program rujukan memungkinkan Anda untuk membuat tautan rujukan unik yang mengundang teman -teman Anda untuk defi penambangan. Jika ada yang mengklik tautan dan penambangan defi, mereka akan secara otomatis dikaitkan sebagai rujukan Anda. Anda akan mendapatkan Anda teman penghargaan penambangan 30% DEFI. ',
    Getlink: 'Dapatkan Tautan Referensi Anda',
    Getlinkdec: 'Harap terhubung ke dompet terlebih dahulu dan dapatkan tautan rujukan.',
    Invitefriends: 'Mengundang teman-teman',
    Invitefriendsdecs: 'Bagikan tautan rujukan Anda ke jejaring sosial dan lacak kinerja setiap penambangan defi di halaman rujukan.',
    Earnrewards: 'Dapatkan hadiah',
    Earnrewardsdec: 'Anda akan mendapatkan Anda teman penghargaan penambangan 30% DEFI.',
     },
 mining: {
all: 'Semua rantai',
 daypro: 'Tingkat hasil harian',
 cycle: ' Periode defi ',
 balance: 'Keseimbangan dompet',
income: 'Hasil harian',
StakingNow:'Mempertaruhkan Sekarang',
details: ' Detail ',
depositcycle: 'Fleksibel',
Joinanonlineminer: 'Bergabunglah dengan penambang online ',
Stakinginformation:'	Informasi Taruhan	',
Annualrateofreturn:'	Tingkat pengembalian tahunan	',
Stakingbalance:'	Mempertaruhkan Saldo	',
Stakingtotalincome:'	Mempertaruhkan total pendapatan	',
Stakingdetails:'	Detail Taruhan	',
chianclose:'	Mata uang ini belum dibuka	',
MiningFees:'Biaya Penambangan Tidak Memadai',
},account:{
  WalletID: 'ID dompet',
  InviteFriends: 'Mengundang teman-teman',
  DeFiMiningYieldBalance: 'DEFI MINING Balance Hasil',


  YieldBalance: 'Hasil keseimbangan',
  ReferralRewards: 'Hadiah Rujukan',
  DailyYieldDetails: 'Detail hasil harian',
  Withdraw:'Menarik',
  GetRewards :'Dapatkan Hadiah',
  WithdrawalHistory: 'Sejarah penarikan',
  Assets: 'Aktiva',
  Amount: 'Jumlah',
  Status: 'Status',
  Nomoredata: 'Tidak ada data lagi',
  complete: 'lengkep',
  publicchain: 'ranté publik',
  time: 'waktos',
  Assetstip: 'aset',
  Amounttip:'kuantitas',

  MinimumWithdrawal: 'Jumlah penarikan minimum',
  InsufficientBalance: 'saldo Anda tidak mencukupi',
  Waitinline: 'Pengiriman berhasil. Karena banyaknya penarikan, harap bersabar menunggu',
  
 }

 }
