export default{

menus: {
 home: ' 家 ',
 mining: ' 債務マイニング ',

 Referral: '照会', 
 account: 'アカウント',
 ConnectWallet: 'ウォレットを接続します',
 
 },
 home: {
  jion: '1つの場所からDefi Miningに参加します',
  jiondefi: ' Defi Hubは、1つの場所からのDefiマイニング全体を構築および管理する最も簡単な方法です.',
  jiondefit: '今日、分散財政の世界を発見してください. ',
  mining: '債務マイニング', 
  Referral: ' 照会 ', 
  bannertitle: '最初にプライバシーとセキュリティ' , 
  bannerdesc: ' defi hubを使用すると、常に制御されています。 ユーザーアカウントはありません。代わりに、メタマスク、CoinbaseなどのWeb3ウォレットを直接使用しています。これは、プライベートキーを所有しておらず、資金にアクセスできないことを意味します。 私たちはウォレットデータを相互接続することはできません、そして私たちはあなたの情報を第三者と決して共有しません. '
  ,banneroepn:'グローバルで、無料で、誰にでもオープン',
  bannerappnum:'何千もの',
  bannerappopen:' サポートされているDefiプロトコルの',
  bannertrade:' 10億ドル以上 ',

  bannerapptrdl:'2023年2月の時点で取引されています',
  bannerusernum:'20億ドル以上',
  bannerusernumlt:' 2023年2月現在の毎月のアクティブユーザー',
  bannerusernumlt:'何か質問がある ？',
  Partner:'信頼されています',
  toptips:'	トランザクション検証者になって、BSC ETH Tron の将来の安全を確保しましょう	',
toptipsleft:'	BSC ETH Tron ネットワークを通じて、トランザクション検証者になることができます。'	,
toptipsre:'	BSC ETH Tron ネットワークのトランザクション検証を提供し、継続的な報酬を受け取ります。	',

  
  },Referral: {
      InviteFriends: '友達を招待',
      EarnRewards : '友達を招待 ',
      InviteFriendsdec: '友達を招待してください。.',
      TotalReferrals: '合計紹介',

    ShareReferralLink: '紹介リンクを共有します',
    
    Copy: ' コピー ',
    YourReferrals: 'あなたの紹介',
    User: 'ユーザー',
    Assets: '資産',
    Rewards: '報酬',
    Nomoredata: 'これ以上のデータはありません',
 
    Referralwork: '  紹介プログラムはどのように機能しますか？ ',
    Referralworkdec: ' 紹介プログラムを使用すると、友人をDefi Miningに招待する独自の紹介リンクを作成できます。リンクとDefi Miningをクリックした場合、紹介として自動的に起因します。 あなたはあなたに友達を得るでしょう30％の債務マイニング報酬. ',
    Getlink: '紹介リンクを取得します',
    Getlinkdec: '最初にウォレットに接続して、紹介リンクを取得してください.',
    Invitefriends: '友達を招待',
    Invitefriendsdecs: 'ソーシャルネットワークへの紹介リンクを共有し、紹介ページで各債務マイニングのパフォーマンスを追跡します.',
    Earnrewards: '報酬を獲得します',
    Earnrewardsdec: 'あなたはあなたに友達を得るでしょう30％の債務マイニング報酬.',
     },
 mining: {
all: 'すべてのチェーン',
 daypro: '毎日の降伏率',
 cycle: ' defi期間 ',
 balance: 'ウォレットバランス',
income: '毎日の利回り',
StakingNow:'今すぐステーキング',
details: ' 詳細 ',
depositcycle: 'フレキシブル',
Joinanonlineminer: 'オンラインマイナーに参加してください ',
Stakinginformation:'ステーキング情報',
Annualrateofreturn:'年間収益率',
Stakingbalance:'ステーキングバランス',
Stakingtotalincome:'ステーキング総収入',
Stakingdetails:'ステーキングの詳細',
chianclose:'この通貨はまだオープンされていません',
MiningFees:'不十分なマイニング手数料',
},account:{
  WalletID: 'ウォレットID',
  InviteFriends: '友達を招待',
  DeFiMiningYieldBalance: 'Defi Mining Hightバランス',
  Assets: '資産',

  YieldBalance: '収穫量のバランス',
  ReferralRewards: '紹介報酬',
  DailyYieldDetails: '毎日の収量の詳細',
  Withdraw:'撤退',
  GetRewards :'報酬を獲得する',
  WithdrawalHistory: '撤退履歴',
  Assets: '資産',
  Amount: '額',
  Status: 'スターテス',
  Nomoredata: 'これ以上のデータはありません',
  complete: '完了',
  publicchain: 'パブリックチェーン',
  time: '時間',
  Assetstip: '資産',
  Amounttip:'量',
  MinimumWithdrawal: '最低出金額',
  InsufficientBalance: '残高が不足しています',
  Waitinline: '提出は成功しました。 出金件数が多いため、気長にお待ちください',
  
 }

 }
