export default{

menus: {
 home: ' घर ',
 mining: ' खनिज खनन ',

 Referral: 'रेफ़रल', 
 account: 'खाता',
 ConnectWallet: 'कनेक्ट वॉलेट',
 
 },
 home: {
  jion: 'एक जगह से डेफी खनन में शामिल हों',
  jiondefi: ' डेफी हब एक स्थान से अपने पूरे डेफी खनन के निर्माण और प्रबंधन का सबसे आसान तरीका है.',
  jiondefit: 'आज विकेंद्रीकृत वित्त की दुनिया की खोज करें. ',
  mining: 'खनिज खनन', 
  Referral: ' रेफ़रल ', 
  bannertitle: 'गोपनीयता और सुरक्षा पहले' , 
  bannerdesc: ' डेफि हब के साथ, आप हमेशा नियंत्रण में हैं, हमेशा। हमारे पास उपयोगकर्ता खाते नहीं हैं और इसके बजाय सीधे मेटामास्क, कॉइनबेस जैसे वेब 3 वॉलेट के साथ काम करते हैं। इसका मतलब है कि हम आपकी निजी कुंजी के मालिक नहीं हैं और कभी भी आपके फंड का उपयोग नहीं कर सकते हैं। हम क्रॉस-एसोसिएट वॉलेट डेटा नहीं करते हैं और हम कभी भी अपनी जानकारी को तृतीय पक्षों के साथ साझा नहीं करेंगे. '
  ,banneroepn:'वैश्विक, स्वतंत्र और किसी के लिए भी खुला',
  bannerappnum:'हजारों',
  bannerappopen:' डीईएफआई प्रोटोकॉल समर्थित',
  bannertrade:' $ 1 बिलियन+ ',

  bannerapptrdl:'फरवरी 2023 तक वॉल्यूम का कारोबार किया गया',
  bannerusernum:'20 बिलियन+ ',
  bannerusernumlt:' फरवरी 2023 तक मासिक सक्रिय उपयोगकर्ता',
  bannerusernumlt:'कोई भी प्रश्न है ?',
  Partner:'पर भरोसा करना',
  toptips:'लेनदेन सत्यापनकर्ता बनें और बीएससी ईटीएच ट्रॉन के भविष्य को सुरक्षित करने में मदद करें',
toptipsleft:'बीएससी ईटीएच ट्रॉन नेटवर्क के माध्यम से, आप लेनदेन सत्यापनकर्ता बन सकते हैं।',
toptipsre:'बीएससी ईटीएच ट्रॉन नेटवर्क के लिए लेनदेन सत्यापन प्रदान करें और निरंतर पुरस्कार प्राप्त करें।',
  
  },Referral: {
      InviteFriends: 'मित्रों को आमंत्रित करें',
      EarnRewards : 'मित्रों को आमंत्रित करें ',
      InviteFriendsdec: 'अपने दोस्तों को शामिल होने के लिए आमंत्रित करें और आप दोस्तों को 30% डेफि माइनिंग रिवार्ड्स मिलेंगे.',
      TotalReferrals: 'कुल रेफरल',

    ShareReferralLink: 'शेयर रेफरल लिंक',
    
    Copy: ' प्रतिलिपि ',
    YourReferrals: 'आपके रेफरल',
    User: 'उपयोगकर्ता',
    Assets: 'संपत्ति',
    Rewards: 'पुरस्कार',
    Nomoredata: 'अधिक डेटा नहीं',
 
    Referralwork: '  रेफरल कार्यक्रम कैसे काम करता है? ',
    Referralworkdec: ' रेफरल प्रोग्राम आपको अद्वितीय रेफरल लिंक बनाने की अनुमति देता है जो आपके दोस्तों को DEFI खनन के लिए आमंत्रित करता है। यदि कोई भी लिंक और DEFI खनन पर क्लिक करता है, तो वे स्वचालित रूप से आपके रेफरल के रूप में जिम्मेदार होंगे। आप आपको 30% डेफि माइनिंग रिवार्ड्स मिलेंगे. ',
    Getlink: 'अपना रेफरल लिंक प्राप्त करें',
    Getlinkdec: 'कृपया पहले वॉलेट से कनेक्ट करें और रेफरल लिंक प्राप्त करें.',
    Invitefriends: 'मित्रों को आमंत्रित करें',
    Invitefriendsdecs: 'सोशल नेटवर्क के लिए अपने रेफरल लिंक को साझा करें और रेफरल पेज पर प्रत्येक डीईएफआई खनन के प्रदर्शन को ट्रैक करें.',
    Earnrewards: 'पुरस्कार अर्जित करें',
    Earnrewardsdec: 'आप आपको 30% डेफि माइनिंग रिवार्ड्स मिलेंगे.',
     },
 mining: {
all: 'सभी चेन',
 daypro: 'दैनिक उपज दर',
 cycle: ' डेफ़ी अवधि ',
 balance: 'बटुआ शेष',
income: 'दैनिक उपज',
StakingNow:'Staking Now',
details: 'विवरण ',
depositcycle: 'लचीला',
Joinanonlineminer: 'एक ऑनलाइन माइनर में शामिल हों ',
Stakinginformation:'दांव लगाने की जानकारी',
Annualrateofreturn:'रिटर्न की वार्षिक दर',
Stakingbalance:'दांव पर संतुलन',
Stakingtotalincome:'कुल आय दांव पर लगाना',
Stakingdetails:'दांव लगाने का विवरण',
chianclose:'इस करेंसी को अभी तक खोला नहीं गया है',
MiningFees:'अपर्याप्त खनन शुल्क',
},account:{
  WalletID: 'बटुए आईडी',
  InviteFriends: 'मित्रों को आमंत्रित करें',
  DeFiMiningYieldBalance: 'खनन खनन उपज संतुलन',
  Assets: 'संपत्ति',
  Withdraw:'निकालना',
  GetRewards :'पुरस्कार पाना',
  YieldBalance: 'उपज शेष',
  ReferralRewards: 'रेफरल रिवार्ड्स',
  DailyYieldDetails: 'दैनिक उपज विवरण',
  
  WithdrawalHistory: 'निकासी इतिहास',

  Amount: 'मात्रा',
  Status: 'दर्जा',
  Nomoredata: 'अधिक डेटा नहीं',
  complete: 'पूरा',
  publicchain: 'सार्वजनिक श्रृंखला',
  time: 'समय',
  Assetstip: 'संपत्ति',
  Amounttip:'मात्रा',
  MinimumWithdrawal: 'न्यूनतम निकासी राशि',
  InsufficientBalance: 'आपका शेष अपर्याप्त है',
  Waitinline: 'सबमिशन सफल है. बड़ी संख्या में निकासी के कारण, कृपया धैर्यपूर्वक प्रतीक्षा करें',
  


 }

 }
