export default{
    table: {
    username: '用户名',
   email: '邮箱',
   mobile: '手机',
  
  },
  menus: {
   home: '首页',
   mining: 'DeFi 挖礦',
 
   Referral: '推薦', 
   account: '帳戶',
   ConnectWallet: '鏈接錢包',
   
   },
   home: {
    jion: '從一處加入 DeFi 挖礦',
    jiondefi: ' DeFi Hub 是一體化構建和管理整個 DeFi 挖礦的最簡單方法。',
    jiondefit: '  立即探索去中心化金融的世界。 ',
    mining: ' DeFi 挖礦 ', 
    Referral: '推薦', 
    bannertitle: ' 隱私和安全第一 ' , 
    bannerdesc: ' 使用 DeFi Hub，您始終可以掌控一切。我們沒有用戶帳戶，而是直接使用 TronLink  , TrustWallet , SafePal、Coinbase 等 Web3 錢包。這意味著我們不擁有您的私鑰，也無法訪問您的資金。我們不會交叉關聯錢包數據，也絕不會與第三方共享您的信息。 '
    ,banneroepn:' 全球、免費、對任何人開放 ',
    bannerappnum:'數千',
    bannerappopen:'支持的 DeFi 協議',
    bannertrade:'10億美元+',
  
    bannerapptrdl:'截至 2023 年 2 月的交易量',
    bannerusernum:'20億美元+',
    bannerusernumlt:' 截至 2023 年 2 月的月活躍用戶數',
    bannerusernumlt:'常見問題',
    Partner:'合作夥伴',
    toptips:'成為交易驗證者並幫助確保BSC ETH Tron的未來',
    toptipsleft:'通過BSC ETH Tron網絡，您可以成為交易驗證者。',
    toptipsre:'為BSC ETH Tron網絡提供交易驗證並獲得持續獎勵。',
    },
    Referral: {
      InviteFriends: ' 邀請朋友',
      EarnRewards : ' 賺取獎勵 ',
      InviteFriendsdec: ' 邀請您的朋友加入，您將獲得朋友 30% DeFi 挖礦獎勵。 ',
      TotalReferrals: '總推薦人',

    ShareReferralLink: '分享推薦鏈接',
    
    Copy: '複製',
    YourReferrals: '您的推薦人',
    User: '用戶',
    Assets: '資產',
    Rewards: '獎勵',
    Nomoredata: '沒有更多的數據',
 
    Referralwork: ' 推薦計劃如何工作 ?',
    Referralworkdec: ' 推薦計劃允許您創建獨特的推薦鏈接，邀請您的朋友進行 DeFi 挖礦。如果有人點擊該鏈接並進行 DeFi 挖礦，他們將被自動歸為您的推薦人。您將獲得朋友 30% DeFi 挖礦獎勵。 ',
    Getlink: '獲取您的推薦鏈接',
    Getlinkdec: '請先連接到錢包並獲取推薦鏈接。',
    Invitefriends: '邀請朋友',
    Invitefriendsdecs: '將您的推薦鏈接分享到社交網絡，並在推薦頁面上跟踪每次 DeFi 挖礦的表現。',
    Earnrewards: '賺取獎勵',
    Earnrewardsdec: '您將獲得您朋友 30% 的 DeFi 挖礦獎勵。',
     },
   mining: {
  all: '所有公鏈',
   daypro: '每日收益率',
   cycle: 'DeFi週期',
   balance: '錢包餘額',
income: '每日收益',
Joinanonlineminer: '成为在线矿工',
details: ' 詳情 ',
depositcycle: '活期',
StakingNow:'立即質押',
Stakinginformation:'質押信息',
Annualrateofreturn:'年化收益率',
Stakingdetails:'質押详情',
Stakingbalance:'質押餘額',
Stakingtotalincome:'質押總收益',
chianclose:'暫未開通此幣種',
Success:"成功了",
Failed:"失敗的",
MiningFees:'挖礦費用不足',
 },
 account:{
  WalletID: '錢包 ID',
  InviteFriends: '邀請朋友',
  DeFiMiningYieldBalance: 'DeFi 挖礦收益餘額',
  Assets: '資產',

  YieldBalance: '收益餘額',
  ReferralRewards: '推薦獎勵',
  DailyYieldDetails: '每日收益詳情',
  Withdraw:'提現 ',
  GetRewards :'獲得獎勵',

  WithdrawalHistory: '提現記錄',
  Assets: '資產',
  Amount: '數量',
  Status: '狀態',
  Nomoredata: '沒有更多的數據',
  complete: '完成',

  TransactionID: '交易ID',


  publicchain: '公鏈:',
  time: '時間:',
  Assetstip: '資產:',
  Amounttip: '數量:',
  MinimumWithdrawal: '最低提款金額',
  InsufficientBalance: '您的餘額不足',
  Waitinline: '提交成功。 由於提現量較大，請耐心等待',

 }

   }
 