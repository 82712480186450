export default{

menus: {
 home: ' Hogar ',
 mining: ' Minería defi ',

 Referral: 'Remisión', 
 account: 'Cuenta',
 ConnectWallet: 'Conectar billetera',
 
 },
 home: {
  jion: 'Únete a Defi Mining desde un lugar',
  jiondefi: ' Defi Hub es la forma más fácil de construir y administrar toda su minería de Defi desde un lugar.',
  jiondefit: 'Descubra el mundo de las finanzas descentralizadas hoy. ',
  mining: 'Minería defi', 
  Referral: ' Remisión ', 
  bannertitle: 'Privacidad y seguridad primero' , 
  bannerdesc: ' Con Defi Hub, tienes el control, siempre. No tenemos cuentas de usuario y, en su lugar, trabajamos directamente con billeteras Web3 como TronLink  , TrustWallet , SafePal, Coinbase.  Esto significa que no poseemos sus claves privadas y nunca podemos acceder a sus fondos. No tenemos datos de billetera cruzada y nunca compartiremos su información con terceros. '
  ,banneroepn:'Global, gratuito y abierto a cualquiera',
  bannerappnum:'miles',
  bannerappopen:' de protocolos defi admitidos',
  bannertrade:' $ 1 mil millones+ ',

  bannerapptrdl:'Volumen negociado a partir de febrero de 2023',
  bannerusernum:'20 mil millones++',
  bannerusernumlt:' Usuarios activos mensuales a partir de febrero de 2023',
  bannerusernumlt:'Tiene alguna pregunta ?',
  Partner:'Confiado por',
  toptips:'Conviértase en un validador de transacciones y ayude a asegurar el futuro de BSC ETH Tron',
toptipsleft:'A través de la red BSC ETH Tron, puede convertirse en un validador de transacciones.',
toptipsre:'Proporcione verificación de transacciones para la red BSC ETH Tron y reciba recompensas continuas.',

  
  },Referral: {
      InviteFriends: 'Invitar a amigos',
      EarnRewards : 'miles',
      InviteFriendsdec: 'Invita a tus amigos a unirse y te conseguirás amigos 30% Defi Mining Rewards.',
      TotalReferrals: 'Referencias totales',

    ShareReferralLink: 'Compartir el enlace de referencia',
    
    Copy: ' Copiar ',
    YourReferrals: 'Tus referencias',
    User: 'Usuario',
    Assets: 'Activos',
    Rewards: 'Recompensas',
    Nomoredata: 'No más datos',
 
    Referralwork: '  ¿Cómo funciona el programa de referencia? ',
    Referralworkdec: ' El programa de referencia le permite crear enlaces de referencia únicos que inviten a sus amigos a Defi Mining. Si alguien hace clic en el enlace y la minería de Defi, se atribuirán automáticamente como su referencia. Te conseguirás amigos 30% de las recompensas de minería de Defi. ',
    Getlink: 'Obtenga su enlace de referencia',
    Getlinkdec: 'Conéctese primero a la billetera y obtenga un enlace de referencia.',
    Invitefriends: 'Invitar a amigos',
    Invitefriendsdecs: 'Comparta su enlace de referencia a las redes sociales y rastree el rendimiento de cada minería Defi en la página de referencia.',
    Earnrewards: 'Gana recompensas',
    Earnrewardsdec: 'Te conseguirás amigos 30% de Defi Mining Rewards.',
     },
 mining: {
all: 'Todas las cadenas',
 daypro: 'Tasa de rendimiento diaria',
 cycle: ' Período defi ',
 balance: 'Equilibrio de billetera',
income: 'Rendimiento diario',
StakingNow:'Replantear ahora',
details: ' Detalles ',
depositcycle: 'Flexible',
Joinanonlineminer: 'Únete a un minero en línea ',

Stakinginformation:'Información de apuesta',
Annualrateofreturn:'Tasa de rendimiento anual',
Stakingbalance:'Saldo de participación',
Stakingtotalincome:'Ingreso total apostado',
Stakingdetails:'Detalles de replanteo',
chianclose:'Esta moneda aún no se ha abierto.',
MiningFees:'Tarifas mineras insuficientes',
},account:{
  WalletID: 'ID de billetera',
  InviteFriends: 'Invitar a amigos',
  DeFiMiningYieldBalance: 'Balance de rendimiento de la minería defi',
  

  YieldBalance: 'Equilibrio de rendimiento',
  ReferralRewards: 'Recompensas de referencia',
  DailyYieldDetails: 'Detalles del rendimiento diario',
  Withdraw:'Retirar',
  GetRewards :'Obtener recompensas',
  WithdrawalHistory: 'Historial de retiro',
  Assets: 'Activos',
  Amount: 'Cantidad',
  Status: 'Estado',
  Nomoredata: 'No más datos',
  complete: 'completo',

  publicchain: 'cadena pública',
  time: 'tiempo',
  Assetstip: 'activos',
  Amounttip: 'cantidad',

 }

 }
