export default{

menus: {
 home: ' Дом ',
 mining: ' Дети Майнинг ',

 Referral: 'Направления', 
 account: 'Счет',
 ConnectWallet: 'Соединить кошелек',
 
 },
 home: {
  jion: 'Присоединяйтесь к Mining Defi из одного места',
  jiondefi: ' Defi Hub - самый простой способ построить и управлять всем своим майнингом Defi из одного места.',
  jiondefit: 'Откройте для себя мир децентрализованных финансов сегодня. ',
  mining: 'Дети Майнинг', 
  Referral: ' Направления ', 
  bannertitle: 'Конфиденциальность и безопасность сначала' , 
  bannerdesc: ' С Defi Hub вы всегда контролируете. Мы не имеем учетных записей пользователей и вместо этого работаем напрямую с Web3 -кошельками, такими как TronLink  , TrustWallet , SafePal, Coinbase.  Это означает, что мы не владеем вашими частными ключами и не можем когда -либо получить доступ к вашим средствам. Мы не передаем данные по перекрестному ассоциированию кошелька, и мы никогда не делимся вашей информацией с третьими лицами. '
  ,banneroepn:'Глобальный, бесплатный и открытый для всех',
  bannerappnum:'тысячи',
  bannerappopen:' протоколов DEFI поддерживаемых',
  bannertrade:' 1 миллиард долларов+ ',

  bannerapptrdl:'Объем торгуется по состоянию на февраль 2023 г.',
  bannerusernum:'20  миллиард долларов+ ',
  bannerusernumlt:' Ежемесячные активные пользователи по состоянию на февраль 2023 г.',
  bannerusernumlt:'Есть какие -либо вопросы ?',
  Partner:'Доверяет',
  toptips:'Станьте валидатором транзакций и помогите обеспечить будущее BSC ETH Tron',
toptipsleft:'Через сеть BSC ETH Tron вы можете стать валидатором транзакций.',
toptipsre:'Обеспечьте проверку транзакций в сети BSC ETH Tron и получайте постоянные вознаграждения.',

MiningFees:'Недостаточная плата за майнинг',
  },Referral: {
      InviteFriends: 'Пригласить друзей',
      EarnRewards : 'Пригласить друзей ',
      InviteFriendsdec: 'Пригласите своих друзей присоединиться, и вы принесете вам друзей 30% Defi Mining Rewards.',
      TotalReferrals: 'Общие рекомендации',

    ShareReferralLink: 'Поделиться ссылкой на реферал',
    
    Copy: ' Копия ',
    YourReferrals: 'Ваши рефералы',
    User: 'Пользователь',
    Assets: 'Ресурсы',
    Rewards: 'Награды',
    Nomoredata: 'Нет больше данных',
 
    Referralwork: '  Как работает реферальная программа? ',
    Referralworkdec: ' Реферальная программа позволяет вам создавать уникальные реферальные ссылки, которые приглашают ваших друзей в Defi Mining. Если кто -либо нажимает на ссылку и майнинг DEFI, они будут автоматически приписываться в качестве вашего направления. Вы получите друзей 30% Defi Mining Rewards. ',
    Getlink: 'Получите ссылку на реферал',
    Getlinkdec: 'Сначала подключитесь к кошельку и получите реферальную ссылку.',
    Invitefriends: 'Пригласить друзей',
    Invitefriendsdecs: 'Поделитесь ссылкой на реферал в социальных сетях и отслеживайте производительность каждого добычи DEFI на странице рефералов.',
    Earnrewards: 'Зарабатывать награды',
    Earnrewardsdec: 'Вы получите друзей 30% Defi Mining Rewards.',
     },
 mining: {
all: 'Все цепи',
 daypro: 'Ежедневный уровень доходности',
 cycle: ' DEFI ПЕРИОД ',
 balance: 'Баланс кошелька',
income: 'Ежедневная доходность',
StakingNow:'Делать ставки сейчас',
details: ' Подробности ',
depositcycle: 'Гибкий',
Joinanonlineminer: 'Присоединяйтесь к онлайн -шахтеру ',

Stakinginformation:'Информация о ставках',
Annualrateofreturn:'Годовая доходность',
Stakingbalance:'Ставка баланса',
Stakingtotalincome:'Общий доход',
Stakingdetails:'Детали стейкинга',
chianclose:'Эта валюта еще не открыта',


},account:{
  WalletID: 'Идентификатор кошелька',
  InviteFriends: 'Пригласить друзей',
  DeFiMiningYieldBalance: 'ДИФИ МАЙНА',
  Assets: 'Ресурсы',
  Withdraw:'Отзывать',
  GetRewards :'Получить награды',
  YieldBalance: 'Баланс доходности',
  ReferralRewards: 'Награды рефералов',
  DailyYieldDetails: 'Ежедневные данные урожайности',
  
  WithdrawalHistory: 'История вывода',
  Assets: 'Ресурсы',
  Amount: 'Количество',
  Status: 'Положение дел',
  Nomoredata: 'Нет больше данных',
  complete: 'completo',

  publicchain: 'публичная сеть',
  time: 'время',
  Assetstip: 'ресурсы',
  Amounttip:'количество',
  MinimumWithdrawal: 'Минимальная сумма вывода',
  InsufficientBalance: 'ваш баланс недостаточен',
  Waitinline: 'Подача прошла успешно. В связи с большим количеством выводов средств, пожалуйста, подождите терпеливо.',
  
 }

 }
