export default{

menus: {
 home: ' Trang chủ ',
 mining: ' Khai thác defi ',

 Referral: 'Giới thiệu', 
 account: 'Tài khoản',
 ConnectWallet: 'Kết nối ví',
 
 },
 home: {
  jion: 'Tham gia vào khai thác defi từ một nơi',
  jiondefi: ' Defi Hub là cách dễ nhất để xây dựng và quản lý toàn bộ khai thác Defi của bạn từ một nơi.',
  jiondefit: 'Khám phá thế giới tài chính phi tập trung ngày nay. ',
  mining: 'Khai thác defi', 
  Referral: ' Giới thiệu ', 
  bannertitle: 'Quyền riêng tư và bảo mật trước tiên' , 
  bannerdesc: ' Với Defi Hub, bạn luôn luôn kiểm soát. Chúng tôi không có tài khoản người dùng và thay vào đó làm việc trực tiếp với các ví Web3 như TronLink  , TrustWallet , SafePal, Coinbase.  Điều này có nghĩa là chúng tôi không sở hữu các khóa riêng của bạn và không thể truy cập vào tiền của bạn. Chúng tôi không có dữ liệu ví liên kết chéo và chúng tôi sẽ không bao giờ chia sẻ thông tin của bạn với các bên thứ ba. '
  ,banneroepn:'Toàn cầu, miễn phí và mở cửa cho bất kỳ ai',
  bannerappnum:'Toàn cầu, miễn phí và mở cửa cho bất kỳ ai',
  bannerappopen:' các giao thức xác định được hỗ trợ',
  bannertrade:' $ 1 tỷ+ ',

  bannerapptrdl:'Khối lượng được giao dịch vào tháng 2 năm 2023',
  bannerusernum:'20  tỷ+ ',
  bannerusernumlt:' Người dùng hoạt động hàng tháng kể từ tháng 2 năm 2023',
  bannerusernumlt:'HCó bất kỳ câu hỏi?',
  Partner:'Đáng tin cậy bởi',
  toptips:'Trở thành người xác thực giao dịch và giúp đảm bảo tương lai của BSC ETH Tron',
toptipsleft:'Thông qua mạng BSC ETH Tron, bạn có thể trở thành người xác thực giao dịch.',
toptipsre:'Cung cấp xác minh giao dịch cho mạng BSC ETH Tron và nhận phần thưởng liên tục.',

  
  },Referral: {
      InviteFriends: 'Mời bạn bè',
      EarnRewards : 'hàng ngàn',
      InviteFriendsdec: 'Mời bạn bè tham gia và bạn sẽ nhận được bạn bè 30% phần thưởng khai thác.',
      TotalReferrals: 'Tổng số giới thiệu',

    ShareReferralLink: 'Chia sẻ liên kết giới thiệu',
    
    Copy: ' Sao chép ',
    YourReferrals: 'Giới thiệu của bạn',
    User: 'Người dùng',
    Assets: 'Tài sản',
    Rewards: 'Phần thưởng',
    Nomoredata: 'Không có thêm dữ liệu',
 
    Referralwork: '  Chương trình giới thiệu hoạt động như thế nào? ',
    Referralworkdec: ' Chương trình giới thiệu cho phép bạn tạo các liên kết giới thiệu độc đáo mời bạn bè của bạn đến khai thác defi. Nếu bất kỳ ai nhấp vào liên kết và khai thác defi, họ sẽ tự động được gán là giới thiệu của bạn. Bạn sẽ nhận được bạn bè 30% phần thưởng khai thác xác định. ',
    Getlink: 'Nhận liên kết giới thiệu của bạn',
    Getlinkdec: 'Vui lòng kết nối với ví trước và nhận liên kết giới thiệu.',
    Invitefriends: 'Mời bạn bè',
    Invitefriendsdecs: 'Chia sẻ liên kết giới thiệu của bạn đến các mạng xã hội và theo dõi hiệu suất của mỗi lần khai thác xác định trên trang giới thiệu.',
    Earnrewards: 'Kiếm phần thưởng',
    Earnrewardsdec: 'Bạn sẽ nhận được bạn bè 30% phần thưởng khai thác xác định.',
     },
 mining: {
all: 'Tất cả các chuỗi',
 daypro: 'Tỷ lệ năng suất hàng ngày',
 cycle: ' Thời kỳ xác định ',
 balance: 'Cân bằng ví',
income: 'Năng suất hàng ngày',
StakingNow:'đặt cược ngay bây giờ',
details: 'Chi tiết ',
depositcycle: 'Linh hoạt',
Joinanonlineminer: 'Tham gia một công cụ khai thác trực tuyến ',
Stakinginformation:'Thông tin đặt cược',
Annualrateofreturn:'Tỷ lệ hoàn vốn hàng năm',
Stakingbalance:'Số dư đặt cược',
Stakingtotalincome:'Tổng thu nhập đặt cược',
Stakingdetails:'Chi tiết đặt cược',
chianclose:'Loại tiền tệ này chưa được mở',
MiningFees:'Phí khai thác không đủ',

},account:{
  WalletID: 'Ví ID',
  InviteFriends: 'Mời bạn bè',
  DeFiMiningYieldBalance: 'Sự cân bằng năng suất khai thác của Defi',


  YieldBalance: 'Cân bằng năng suất',
  ReferralRewards: 'Phần thưởng giới thiệu',
  DailyYieldDetails: 'Chi tiết sản lượng hàng ngày',
  Withdraw:'Rút',
  GetRewards :'Được nhận thưởng',
  WithdrawalHistory: 'Lịch sử rút tiền',
  Assets: 'Tài sản',
  Amount: 'Số lượng',
  Status: 'Trạng thái',
  Nomoredata: 'Không có thêm dữ liệu',
  complete: 'hoàn thành',

  publicchain: 'chuỗi công cộng',
  time: 'thời gian',
  Assetstip: 'tài sản',
  Amounttip:'Số lượng',

  MinimumWithdrawal: 'Số tiền rút tối thiểu',
  InsufficientBalance: 'số dư của bạn không đủ',
  Waitinline: 'Việc gửi thành công. Do số lượng rút lớn nên hãy kiên nhẫn chờ đợi',
  
 }

 }
