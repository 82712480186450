export default{

menus: {
 home: ' Heim ',
 mining: 'Defi -Bergbau',

 Referral: 'Verweisung', 
 account: 'Konto',
 ConnectWallet: 'Brieftasche anschließen',
 
 },
 home: {
  jion: 'Nehmen Sie an einem Defi -Bergbau von einem Ort teil',
  jiondefi: ' Defi Hub ist der einfachste Weg, um Ihren gesamten Defi -Bergbau von einem Ort aus zu bauen und zu verwalten.',
  jiondefit: 'Entdecken Sie heute die Welt der dezentralen Finanzen. ',
  mining: 'Defi -Bergbau', 
  Referral: ' Verweisung ', 
  bannertitle: 'Privatsphäre und Sicherheit zuerst' , 
  bannerdesc: ' Mit Defi Hub sind Sie immer die Kontrolle. Wir haben keine Benutzerkonten und arbeiten stattdessen direkt mit Web3 -Geldbörsen wie TronLink  , TrustWallet , SafePal, Coinbase.  Dies bedeutet, dass wir Ihre privaten Schlüssel nicht besitzen und niemals auf Ihre Mittel zugreifen können. Wir sind nicht übereinstimmende Brieftaschendaten und teilen Ihre Informationen niemals an Dritte. '
  ,banneroepn:'Global, frei und offen für jeden',
  bannerappnum:'Tausende',
  bannerappopen:' von Defi -Protokollen unterstützt',
  bannertrade:' 1 Milliarde US -Dollar+ ',

  bannerapptrdl:'Volumen gehandelt im Februar 2023',
  bannerusernum:'20 Milliarde+',
  bannerusernumlt:' Monatliche aktive Benutzer im Februar 2023',
  bannerusernumlt:'Fragen Sie Fragen ?',
  Partner:'Vertrauen von',

  
  },Referral: {
      InviteFriends: 'Freunde einladen',
      EarnRewards : 'Freunde einladen ',
      InviteFriendsdec: 'Laden Sie Ihre Freunde ein, sich anzuschließen, und Sie werden Ihnen Freunde 30% Defi Mining Rewards erhalten.',
      TotalReferrals: 'Gesamtüberweisungen',

    ShareReferralLink: 'Überweisungslink Freigabe',
    
    Copy: ' Kopieren ',
    YourReferrals: 'Ihre Empfehlungen',
    User: 'Benutzer',
    Assets: 'Vermögenswerte',
    Rewards: 'Belohnung',
    Nomoredata: 'Keine Daten mehr',
 
    Referralwork: '  Wie funktioniert das Empfehlungsprogramm? ',
    Referralworkdec: ' Mit dem Empfehlungsprogramm können Sie eindeutige Empfehlungslinks erstellen, die Ihre Freunde zum Defi -Mining einladen. Wenn jemand auf den Link und den Defi -Mining klickt, werden sie automatisch als Überweisung zugeordnet. Sie werden Sie Freunde besorgen 30% Defi Mining Rewards. ',
    Getlink: 'Holen Sie sich Ihren Empfehlungslink',
    Getlinkdec: 'Bitte verbinden Sie zuerst mit der Brieftasche und erhalten Sie den Überweisungslink.',
    Invitefriends: 'Freunde einladen',
    Invitefriendsdecs: 'Teilen Sie Ihren Empfehlungslink an soziale Netzwerke und verfolgen Sie die Leistung jedes Defi -Mining auf der Überweisungsseite.',
    Earnrewards: 'Prämien verdienen',
    Earnrewardsdec: 'YSie werden Sie Freunde besorgen 30% Defi Mining Rewards.',
     },
 mining: {
all: 'Alle Ketten',
 daypro: 'Tägliche Ertragsrate',
 cycle: ' Defi -Periode ',
 balance: 'Brieftaschenbalance',
income: 'Tägliche Ertrag',

details: ' Einzelheiten ',
depositcycle: 'Flexibel',
StakingNow:'Jetzt abstecken',
Joinanonlineminer: 'Treten Sie einem Online -Bergmann bei ',
Stakinginformation:'Absteckinformationen',
Annualrateofreturn:'Jährliche Rendite',
Stakingbalance:'Einsatzguthaben',
Stakingtotalincome:'Gesamteinkommen abstecken',
Stakingdetails:'Details zum Abstecken',
chianclose:'Diese Währung wurde noch nicht geöffnet',
MiningFees:'Unzureichende Bergbaugebühren',
},account:{
  WalletID: 'Brieftaschen -ID',
  InviteFriends: 'Freunde einladen',
  DeFiMiningYieldBalance: 'Defi -Bergbau -Ertragsbilanz',
  Assets: 'AVermögenswerte',

  YieldBalance: 'Ertragsbilanz',
  ReferralRewards: 'Überweisungsbelohnungen',
  DailyYieldDetails: 'Tägliche Ertragsdetails',
  
  WithdrawalHistory: 'Rückzugsgeschichte',
  Withdraw:'Zurückziehen',
  GetRewards :'Erhalten Sie Belohnungen',
  Amount: 'Menge',
  Status: 'Status',
  Nomoredata: 'Keine Daten mehr',
  complete: 'vollständig',
  MinimumWithdrawal: 'Mindestauszahlungsbetrag',
InsufficientBalance: 'Ihr Guthaben reicht nicht aus',
Waitinline: 'Die Einreichung ist erfolgreich. Bitte warten Sie aufgrund der großen Anzahl an Abhebungen geduldig',
MinimumWithdrawal: 'Monto mínimo de retiro',
InsufficientBalance: 'tu saldo es insuficiente',
Waitinline: 'El envío es exitoso. Debido a la gran cantidad de retiros, espere pacientemente',

 }

 }
