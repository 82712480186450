export default{

menus: {
 home: ' Home ',
 mining: ' Defi Mining ',

 Referral: 'Referral', 
 account: 'Account',
 ConnectWallet: 'Ikonekta ang pitaka',
 
 },
 home: {
  jion: 'Sumali sa Defi Mining mula sa isang lugar',
  jiondefi: ' Ang Defi Hub ay ang pinakamadaling paraan upang mabuo at pamahalaan ang iyong buong defi pagmimina mula sa isang lugar.',
  jiondefit: 'Tuklasin ang mundo ng desentralisadong pananalapi ngayon. ',
  mining: 'Defi Mining', 
  Referral: ' Referral ', 
  bannertitle: 'Una sa privacy at seguridad' , 
  bannerdesc: ' Sa defi hub, hindi ka makontrol, palagi. Kami ay mayroong mga account sa gumagamit at sa halip ay gumana nang direkta sa mga Web3 wallets tulad ng TronLink  , TrustWallet , SafePal, Coinbase. Ito ay nangangahulugang hindi namin pagmamay -ari ang iyong mga pribadong key at hindi ma -access ang iyong mga pondo. Hindi namin i-cross-associate na data ng pitaka at hindi namin ibabahagi ang iyong impormasyon sa mga third party. '
  ,banneroepn:'Global, libre at bukas sa sinuman',
  bannerappnum:'libo',
  bannerappopen:' ng mga defi protocol na suportado',
  bannertrade:' $ 1 bilyon+ ',

  bannerapptrdl:'Ang dami ng ipinagpalit noong Pebrero 2023',
  bannerusernum:'20 bilyon+',
  bannerusernumlt:' Buwanang aktibong gumagamit ng Pebrero 2023',
  bannerusernumlt:'May mga katanungan ?',
  Partner:'Pinagkakatiwalaan ng',
  toptips:'Maging validator ng transaksyon at tumulong sa pag-secure ng kinabukasan ng BSC ETH Tron',
toptipsleft:'Sa pamamagitan ng BSC ETH Tron network, maaari kang maging validator ng transaksyon.',
toptipsre:'Magbigay ng pag-verify ng transaksyon para sa BSC ETH Tron network at makatanggap ng tuluy-tuloy na mga reward.',

  
  },Referral: {
      InviteFriends: 'Mag-imbita ng mga kaibigan',
      EarnRewards : 'Mag-imbita ng mga kaibigan ',
      InviteFriendsdec: 'Anyayahan ang iyong mga kaibigan na sumali at makakakuha ka ng mga kaibigan 30% defi mining reward.',
      TotalReferrals: 'Kabuuang mga sanggunian',

    ShareReferralLink: 'Ibahagi ang link ng referral',
    
    Copy: ' Kopya ',
    YourReferrals: 'Ang iyong mga sanggunian',
    User: 'Gumagamit',
    Assets: 'Mga assets',
    Rewards: 'Gantimpala',
    Nomoredata: 'Wala nang data',
 
    Referralwork: '  Paano gumagana ang programa ng referral? ',
    Referralworkdec: ' Pinapayagan ka ng programa ng referral na lumikha ng mga natatanging mga link sa referral na nag -aanyaya sa iyong mga kaibigan na defi mining.Kung ang sinumang nag -click sa link at defi mining, awtomatikong maiugnay sila bilang iyong referral. Makakakuha ka ng mga kaibigan 30% defi na gantimpala ng pagmimina. ',
    Getlink: 'Kunin ang iyong link sa referral',
    Getlinkdec: 'Mangyaring kumonekta muna sa Wallet at kumuha ng link ng referral.',
    Invitefriends: 'Mag-imbita ng mga kaibigan',
    Invitefriendsdecs: 'Ibahagi ang iyong link sa referral sa mga social network at subaybayan ang pagganap ng bawat defi mining sa pahina ng referral.',
    Earnrewards: 'Kumita ng mga gantimpala',
    Earnrewardsdec: 'Makakakuha ka ng mga kaibigan 30% defi na gantimpala ng pagmimina.',
     },
 mining: {
all: 'Lahat ng mga tanikala',
 daypro: 'Pang -araw -araw na rate ng ani',
 cycle: ' Panahon ng defi ',
 balance: 'Balanse ng Wallet',
income: 'Pang -araw -araw na ani',
StakingNow:'Staking Ngayon',
details: ' Mga detalye ',
depositcycle: 'Nababaluktot',
Joinanonlineminer: 'Sumali sa isang online na minero ',
Stakinginformation:'Impormasyon sa Staking',
Annualrateofreturn:'Taunang rate ng pagbabalik',
Stakingbalance:'Staking Balanse',
Stakingtotalincome:'Staking kabuuang kita',
Stakingdetails:'Mga Detalye ng Staking',
chianclose:'Ang pera na ito ay hindi pa nabubuksan',
MiningFees:'Hindi Sapat na Bayad sa Pagmimina',
},account:{
  WalletID: 'Wallet Id',
  InviteFriends: 'Mag-imbita ng mga kaibigan',
  DeFiMiningYieldBalance: 'Defi mining ani balanse',
  Assets: 'Mga assets',

  YieldBalance: 'Balanse ng ani',
  ReferralRewards: 'Mga gantimpala ng referral',
  DailyYieldDetails: 'Pang -araw -araw na mga detalye ng ani',
  
  WithdrawalHistory: 'Kasaysayan ng pag -atras',
  Withdraw:'Mag-withdraw',
  GetRewards :'Kumuha ng Mga Gantimpala',
  Amount: 'Halaga',
  Status: 'Katayuan',
  Nomoredata: 'Wala nang data',
  complete: 'kumpleto',

  publicchain: 'pampublikong kadena',
  time: 'oras',
  Assetstip: 'mga ari-arian',
  Amounttip:'dami',
  MinimumWithdrawal: 'Minimum na halaga ng withdrawal',
  InsufficientBalance: 'kulang ang balanse mo',
  Waitinline: 'Ang pagsusumite ay matagumpay. Dahil sa malaking bilang ng mga withdrawal, mangyaring maghintay nang matiyaga',
  
 }

 }
