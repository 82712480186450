export default{

menus: {
 home: ' Домашній ',
 mining: ' Видобуток дефін ',

 Referral: 'Направлення', 
 account: 'Рахунок',
 ConnectWallet: 'Підключіть гаманець',
 
 },
 home: {
  jion: 'Приєднуйтесь до видобутку Defi з одного місця',
  jiondefi: ' Defi Hub - це найпростіший спосіб побудувати та керувати всім видобутком Defi з одного місця.',
  jiondefit: 'Відкрийте світ децентралізованих фінансів сьогодні. ',
  mining: 'Видобуток дефін', 
  Referral: ' Направлення ', 
  bannertitle: 'Конфіденційність та безпека спочатку' , 
  bannerdesc: ' З Defi Hub, ти завжди контролюєшся. Ми не маємо облікових записів користувачів і замість цього працюємо безпосередньо з гаманцями Web3, як TronLink  , TrustWallet , SafePal, Coinbase.  Це означає, що ми не маємо ваших приватних ключів і не можемо отримати доступ до ваших коштів. Ми не маємо даних про перехресного гаманця, і ми ніколи не ділимося вашою інформацією з третіми сторонами. '
  ,banneroepn:'Глобальний, безкоштовний і відкритий для будь -кого',
  bannerappnum:'тисячі',
  bannerappopen:' підтримуваних протоколів Defi',
  bannertrade:' 1 мільярд доларів+ ',

  bannerapptrdl:'Обсяг торгується станом на лютий 2023 року',
  bannerusernum:'20 мільярд доларів+ ',
  bannerusernumlt:' Щомісячні активні користувачі станом на лютий 2023 року',
  bannerusernumlt:'Є якісь запитання?',
  Partner:'Довіряю',
  toptips:'Станьте валідатором транзакцій і допоможіть забезпечити майбутнє BSC ETH Tron',
toptipsleft:'Через мережу BSC ETH Tron ви можете стати валідатором транзакцій.',
toptipsre:'Забезпечуйте перевірку транзакцій для мережі BSC ETH Tron і отримуйте постійні винагороди.',

  
  },Referral: {
      InviteFriends: 'Запросити друзів',
      EarnRewards : 'заробити нагороди',
      InviteFriendsdec: 'Запросіть своїх друзів приєднатися, і ви знайдете друзів 30% нагороди Defi Mining.',
      TotalReferrals: 'Всього',

    ShareReferralLink: 'Поділитися посиланням на реферал',
    
    Copy: ' Копіювати ',
    YourReferrals: 'Ваші реферали',
    User: 'Користувач',
    Assets: 'Активи',
    Rewards: 'Нагород',
    Nomoredata: 'Більше немає даних',
 
    Referralwork: '  Як працює реферальна програма? ',
    Referralworkdec: ' Реферальна програма дозволяє створити унікальні посилання на реферал, які запрошують ваших друзів до видобутку. Якщо хтось натисне посилання та видобуток Defi, вони будуть автоматично віднесені до вашого рефералу. Ви отримаєте друзі 30% нагороди Defi Mining. ',
    Getlink: 'Отримайте своє посилання на реферал',
    Getlinkdec: 'Будь ласка, підключіться до гаманця спочатку та отримайте посилання на реферал.',
    Invitefriends: 'Запросити друзів',
    Invitefriendsdecs: 'Поділіться своїм посиланням на реферал на соціальні мережі та відстежуйте ефективність кожного видобутку Defi на сторінці рефералу.',
    Earnrewards: 'Заробляти нагороди',
    Earnrewardsdec: 'Ви отримаєте друзі 30% нагороди Defi Mining.',
     },
 mining: {
all: 'Всі ланцюги',
 daypro: 'Щоденний показник урожайності',
 cycle: ' Період ',
 balance: 'Баланс гаманця',
income: 'Щоденний урожай',
StakingNow:'Ставка зараз',
details: ' Деталі ',
depositcycle: 'Гнучкий',
Joinanonlineminer: 'Приєднуйтесь до онлайн -шахтаря ',

Stakinginformation:'Інформація про ставку',
Annualrateofreturn:'Річна норма прибутку',
Stakingbalance:'Баланс ставки',
Stakingtotalincome:'Ставлення загального доходу',
Stakingdetails:'Деталі ставки',
chianclose:'Ця валюта ще не відкрита',
MiningFees:'Недостатня плата за майнінг',
},account:{
  WalletID: 'Ідентифікатор гаманця',
  InviteFriends: 'Запросити друзів',
  DeFiMiningYieldBalance: 'Дефіцорський баланс урожайності',
  
  Withdraw:'Вилучити',
  GetRewards :'Отримайте нагороди',
  YieldBalance: 'Баланс урожайності',
  ReferralRewards: 'Нагородження',
  DailyYieldDetails: 'Деталі щоденного врожаю',
  
  WithdrawalHistory: 'Історія виведення',
  Assets: 'Активи',
  Amount: 'Загальнийt',
  Status: 'Статус',
  Nomoredata: 'Більше немає даних',
  complete: 'повний',
  publicchain: 'публічний ланцюг',
  time: 'час',
  Assetstip: 'активів',
  Amounttip:'кількість',
  MinimumWithdrawal: 'Мінімальна сума для зняття',
  InsufficientBalance: 'ваш баланс недостатній',
  Waitinline: 'Подання успішне. У зв’язку з великою кількістю вилучень, будь ласка, терпляче зачекайте',
  
 }

 }
