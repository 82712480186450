export default{

menus: {
 home: ' 집 ',
 mining: ' Defi Mining ',

 Referral: '추천', 
 account: '계정',
 ConnectWallet: '지갑을 연결하십시오',
 
 },
 home: {
  jion: '한 곳에서 Defi Mining에 참여하십시오',
  jiondefi: ' Defi Hub는 한 곳에서 전체 Defi 채굴을 구축하고 관리하는 가장 쉬운 방법입니다..',
  jiondefit: '오늘날 분산 금융의 세계를 발견하십시오. ',
  mining: 'DeFi Mining', 
  Referral: ' 추천 ', 
  bannertitle: '개인 정보 및 보안을 먼저' , 
  bannerdesc: ' Defi Hub를 사용하면 항상 통제 할 수 있습니다. 우리는 사용자 계정을 가지고 있지 않고 대신 TronLink  , TrustWallet , SafePal, Coinbase와 같은 Web3 지갑과 직접 협력합니다. 이것은 귀하의 개인 키를 소유하지 않고 자금에 액세스 할 수 없다는 것을 의미합니다. 우리는 교차 관련 지갑 데이터를하지 않으며 우리는 귀하의 정보를 제 3 자와 공유하지 않을 것입니다.. '
  ,banneroepn:'글로벌, 무료이며 누구나 개방적입니다',
  bannerappnum:'글로벌, 무료이며 누구나 개방적입니다',
  bannerappopen:'지원되는 Defi 프로토콜의',
  bannertrade:' 10 억 달러+ ',

  bannerapptrdl:'2023 년 2 월 현재 거래량',
  bannerusernum:'20 억 달러+',
  bannerusernumlt:' 2023 년 2 월 현재 월간 활성 사용자',
  bannerusernumlt:'질문이 있습니까?',
  Partner:'신뢰합니다',
  toptips:'트랜잭션 유효성 검사기가 되어 BSC ETH Tron의 미래를 확보하도록 돕습니다.',
toptipsleft:'BSC ETH Tron 네트워크를 통해 트랜잭션 검증자가 될 수 있습니다.',
toptipsre:'BSC ETH Tron 네트워크에 대한 거래 검증을 제공하고 지속적인 보상을 받으세요.',

  
  
  },Referral: {
      InviteFriends: '친구를 초대',
      EarnRewards : '수천',
      InviteFriendsdec: '친구들을 초대하도록 초대하면 친구를 데려 갈 수 있습니다. 30% Defi Mining Rewards.',
      TotalReferrals: '총 추천',

    ShareReferralLink: '공유 추천 링크',
    
    Copy: ' 복사 ',
    YourReferrals: '당신의 추천',
    User: '사용자',
    Assets: '자산',
    Rewards: '보상',
    Nomoredata: '더 이상 데이터가 없습니다',
 
    Referralwork: '  추천 프로그램은 어떻게 작동합니까? ',
    Referralworkdec: ' 추천 프로그램을 사용하면 친구가 결함 광업을 초대하는 고유 한 추천 링크를 만들 수 있습니다. 누군가 링크 및 디피 마이닝을 클릭하면 자동으로 추천으로 기인합니다. 당신은 당신에게 친구를 얻을 것입니다 30% Defi Mining Rewards. ',
    Getlink: '추천 링크를 받으십시오',
    Getlinkdec: '먼저 지갑에 연결하고 추천 링크를 얻으십시오.',
    Invitefriends: '친구를 초대',
    Invitefriendsdecs: '소셜 네트워크에 대한 추천 링크를 공유하고 추천 페이지에서 각 사전 채굴의 성능을 추적하십시오..',
    Earnrewards: '보상을 받으십시오',
    Earnrewardsdec: '당신은 당신에게 친구를 얻을 것입니다 30% Defi Mining Rewards.',
     },
 mining: {
all: '모든 체인',
 daypro: '일일 수율',
 cycle: ' 결함 기간 ',
 balance: '지갑 균형',
income: '일일 수율',
StakingNow:'지금 스테이킹',
details: ' 세부 ',
depositcycle: '유연한',
Joinanonlineminer: '온라인 광부에 가입하십시오 ',
Stakinginformation:'스테이킹 정보',
Annualrateofreturn:'연간 수익률',
Stakingbalance:'스테이킹 잔액',
Stakingtotalincome:'스테이킹 총 수입',
Stakingdetails:'스테이킹 세부 정보',
chianclose:'이 통화는 아직 개설되지 않았습니다.',
MiningFees:'부족한 채굴 비용',
},account:{
  WalletID: '지갑 ID',
  InviteFriends: '친구를 초대',
  DeFiMiningYieldBalance: '결함 채굴 수율 균형',
  Assets: '자산',

  YieldBalance: '수율 균형',
  ReferralRewards: '추천 보상',
  DailyYieldDetails: '일일 수율 세부 사항',
  Withdraw:'철회하다',
  GetRewards :'보상 받기',
  WithdrawalHistory: '철수 기록',

  Amount: '양',
  Status: '상태',
  Nomoredata: '더 이상 데이터가 없습니다',
  complete: '완벽한',
  publicchain: '퍼블릭 체인',
  time: '시간',
  Assetstip: '자산',
  Amounttip:'수량',
  MinimumWithdrawal: '최소 출금 금액',
  InsufficientBalance: '잔액이 부족해요',
  Waitinline: '제출이 성공했습니다. 출금량이 많으므로 조금만 기다려주세요.',
  
 }

 }
