export default{

menus: {
 home: ' Ev ',
 mining: ' Defi Madencilik ',

 Referral: 'Referans', 
 account: 'Hesap',
 ConnectWallet: 'Cüzdan Bağlayın',
 
 },
 home: {
  jion: 'Bir yerden defi madenciliğine katılın',
  jiondefi: ' Defi Hub, tüm defi madenciliğinizi tek bir yerden inşa etmenin ve yönetmenin en kolay yoludur.',
  jiondefit: 'Bugün merkezi olmayan finans dünyasını keşfedin. ',
  mining: 'Defi Madencilik', 
  Referral: ' Referans ', 
  bannertitle: 'Önce Gizlilik ve Güvenlik' , 
  bannerdesc: ' Defi hub ile, her zaman kontrol altında. Kullanıcı hesaplarımız yok ve bunun yerine doğrudan TronLink  , TrustWallet , SafePal, Coinbase gibi Web3 cüzdanları ile çalışıyoruz.Bu, özel anahtarlarınıza sahip olmadığımız ve fonlarınıza erişemediğimiz anlamına geliyor. Cüzdanlar arası verileri yok ve bilgilerinizi asla üçüncü taraflarla paylaşmayacağız. '
  ,banneroepn:'Küresel, özgür ve herkese açık',
  bannerappnum:'binlerce',
  bannerappopen:' desteklenen defi protokollerinin',
  bannertrade:' 1 milyar dolar+ ',

  bannerapptrdl:'Şubat 2023 itibariyle işlem gören hacim',
  bannerusernum:'20 milyar dolar+ ',
  bannerusernumlt:' Şubat 2023 itibariyle aylık aktif kullanıcılar',
  bannerusernumlt:'Herhangi bir sorunun var mı?',
  Partner:'Güvenenler',
  toptips:'Bir işlem doğrulayıcı olun ve BSC ETH Tron\'un geleceğini güvence altına almaya yardımcı olun',
  toptipsleft:'BSC ETH Tron ağı aracılığıyla işlem doğrulayıcı olabilirsiniz.',
  toptipsre:'BSC ETH Tron ağı için işlem doğrulaması sağlayın ve sürekli ödüller kazanın.',
  
  
  },Referral: {
      InviteFriends: 'Arkadaşları davet etmek',
      EarnRewards : 'Arkadaşları davet etmek ',
      InviteFriendsdec: 'Arkadaşlarınızı katılmaya davet edin ve size arkadaşlarına% 30 defi madencilik ödüllerini alacaksınız.',
      TotalReferrals: 'Toplam Tavsiyeler',

    ShareReferralLink: 'Tavsiye Bağlantısını Paylaşın',
    
    Copy: ' Kopya ',
    YourReferrals: 'Tavsiyeleriniz',
    User: 'Kullanıcı',
    Assets: 'Varlıklar',
    Rewards: 'Ödüller',
    Nomoredata: 'Artık veri yok',
 
    Referralwork: '  Tavsiye programı nasıl çalışır? ',
    Referralworkdec: ' Tavsiye programı, arkadaşlarınızı madenciliği tanımlamaya davet eden benzersiz yönlendirme bağlantıları oluşturmanıza olanak tanır.Bir bağlantıyı ve defi madenciliğini tıklatırsa, otomatik olarak yönlendirmeniz olarak ilişkilendirilecektir. Size arkadaş edineceksiniz,% 30 Defi Madencilik Ödülleri. ',
    Getlink: 'Tavsiye Bağlantınızı Alın',
    Getlinkdec: 'Lütfen önce cüzdana bağlanın ve sevk bağlantısı alın.',
    Invitefriends: 'Arkadaşları davet etmek',
    Invitefriendsdecs: 'Sosyal ağlara yönlendirme bağlantınızı paylaşın ve her defi madenciliğinin performansını sevk sayfasında izleyin.',
    Earnrewards: 'Ödül kazanın',
    Earnrewardsdec: 'Size arkadaş edineceksiniz,% 30 Defi Madencilik Ödülleri.',
     },
 mining: {
all: 'Tüm zincirler',
 daypro: 'Günlük verim oranı',
 cycle: ' Defi dönemi ',
 balance: 'Cüzdan bakiyesi',
income: 'Günlük verim',
StakingNow:'Şimdi Stake Etme',
details: ' Detaylar ',
depositcycle: 'Esnek',
Joinanonlineminer: 'Bir Çevrimiçi Madenciye Katılın ',
Stakinginformation:'Staking Bilgileri',
Annualrateofreturn:'Yıllık getiri oranı',
Stakingbalance:'Bahis Bakiyesi',
Stakingtotalincome:'Toplam geliri belirleme',
Stakingdetails:'Staking Detayları',
chianclose:'Bu para birimi henüz açılmadı',
MiningFees:'Yetersiz Madencilik Ücretleri',
},account:{
  WalletID: 'Cüzdan kimliği',
  InviteFriends: 'Arkadaşları davet etmek',
  DeFiMiningYieldBalance: 'Defi Madencilik Verim Dengesi',
  Assets: 'Varlıklar',
  Withdraw:'Geri çekilmek',
  GetRewards :'Ödül almak',
  YieldBalance: 'Verim dengesi',
  ReferralRewards: 'Tavsiye Ödülleri',
  DailyYieldDetails: 'Günlük Verim Ayrıntıları',
  
  WithdrawalHistory: 'Para çekme geçmişi',
  Assets: 'Varlıklar',
  Amount: 'Büyümekt',
  Status: 'Durum',
  Nomoredata: 'Artık veri yok',
  complete: 'tamamlamak',

  publicchain: 'halka açık zincir',
  time: 'zaman',
  Assetstip: 'varlıklar',
  Amounttip:'miktar',
  MinimumWithdrawal: 'Minimum para çekme tutarı',
  InsufficientBalance: 'bakiyeniz yetersiz',
  Waitinline: 'Gönderim başarılı. Çok sayıda para çekme işlemi nedeniyle lütfen sabırla bekleyin',
  
 }

 }
