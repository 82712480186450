export default{

menus: {
 home: ' บ้าน ',
 mining: ' การขุด defi ',

 Referral: 'การอ้างอิง', 
 account: 'บัญชีt',
 ConnectWallet: 'เชื่อมต่อกระเป๋าเงิน',
 
 },
 home: {
  jion: 'เข้าร่วมในการขุด defi จากที่เดียว',
  jiondefi: ' Defi Hub เป็นวิธีที่ง่ายที่สุดในการสร้างและจัดการการขุด defi ทั้งหมดของคุณจากที่เดียว.',
  jiondefit: 'ค้นพบโลกแห่งการเงินที่กระจายอำนาจในวันนี้. ',
  mining: 'การขุด defi', 
  Referral: ' การอ้างอิง ', 
  bannertitle: 'ความเป็นส่วนตัวและความปลอดภัยก่อน' , 
  bannerdesc: ' ด้วย defi hub คุณอยู่ในการควบคุมเสมอ เราไม่มีบัญชีผู้ใช้และทำงานโดยตรงกับ Web3 Wallets เช่น TronLink  , TrustWallet , SafePal, Coinbase ซึ่งหมายความว่าเราไม่ได้เป็นเจ้าของกุญแจส่วนตัวของคุณและไม่สามารถเข้าถึงเงินของคุณได้ เราไม่ได้รับข้อมูลกระเป๋าเงินที่เชื่อมโยงกันและเราจะไม่แบ่งปันข้อมูลของคุณกับบุคคลที่สาม. '
  ,banneroepn:'ทั่วโลกฟรีและเปิดให้ทุกคน',
  bannerappnum:'หลายพัน',
  bannerappopen:' สนับสนุนโปรโตคอล defi',
  bannertrade:' $ 1 พันล้าน+ ',

  bannerapptrdl:'ปริมาณการซื้อขาย ณ เดือนกุมภาพันธ์ 2566',
  bannerusernum:'20 พันล้าน+ ',
  bannerusernumlt:' ผู้ใช้งานรายเดือน ณ เดือนกุมภาพันธ์ 2566',
  bannerusernumlt:'มีคำถาม?',
  Partner:'ได้รับความไว้วางใจจาก',
  toptips:'มาเป็นผู้ตรวจสอบการทำธุรกรรมและช่วยรักษาความปลอดภัยในอนาคตของ BSC ETH Tron',
  toptipsleft:'ผ่านเครือข่าย BSC ETH Tron คุณสามารถเป็นผู้ตรวจสอบธุรกรรมได้',
  toptipsre:'ให้การตรวจสอบการทำธุรกรรมสำหรับเครือข่าย BSC ETH Tron และรับรางวัลอย่างต่อเนื่อง',


  
  },Referral: {
      InviteFriends: 'เชิญเพื่อน ๆ',
      EarnRewards : 'เชิญเพื่อน ๆ ',
      InviteFriendsdec: 'เชิญเพื่อนของคุณเข้าร่วมและคุณจะได้เพื่อน 30% defi Mining Rewards.',
      TotalReferrals: 'การอ้างอิงทั้งหมด',

    ShareReferralLink: 'แชร์ลิงก์อ้างอิง',
    
    Copy: 'สำเนา',
    YourReferrals: 'การอ้างอิงของคุณ',
    User: 'ผู้ใช้',
    Assets: 'สินทรัพย์',
    Rewards: 'รางวัล',
    Nomoredata: 'ไม่มีข้อมูลเพิ่มเติม',
 
    Referralwork: '  โปรแกรมการอ้างอิงทำงานอย่างไร? ',
    Referralworkdec: ' โปรแกรมการอ้างอิงช่วยให้คุณสร้างลิงก์อ้างอิงที่ไม่ซ้ำกันที่เชิญเพื่อนของคุณให้ defi mining หากใครก็ตามคลิกลิงก์และการขุด defi พวกเขาจะถูกนำมาใช้โดยอัตโนมัติเป็นการอ้างอิงของคุณ คุณจะได้รับเพื่อน 30% defi รางวัลการขุด. ',
    Getlink: 'รับลิงค์การอ้างอิงของคุณ',
    Getlinkdec: 'โปรดเชื่อมต่อกับกระเป๋าเงินก่อนและรับลิงค์อ้างอิง.',
    Invitefriends: 'เชิญเพื่อน ๆ',
    Invitefriendsdecs: 'แชร์ลิงก์อ้างอิงของคุณไปยังเครือข่ายสังคมออนไลน์และติดตามประสิทธิภาพของการขุด defi แต่ละครั้งในหน้าการอ้างอิง.',
    Earnrewards: 'รับรางวัล',
    Earnrewardsdec: 'คุณจะได้รับเพื่อน 30% defi รางวัลการขุด.',
     },
 mining: {
all: 'โซ่ทั้งหมด',
 daypro: 'อัตราผลตอบแทนรายวัน',
 cycle: ' ระยะเวลา defi ',
 balance: 'ความสมดุลของกระเป๋าเงิน',
income: 'ผลผลิตรายวัน',
StakingNow:'เดิมพันตอนนี้',
details: ' รายละเอียด ',
depositcycle: 'ยืดหยุ่นได้',
Joinanonlineminer: 'เข้าร่วมนักขุดออนไลน์ ',
Stakinginformation:'	ข้อมูลการเดิมพัน	',
Annualrateofreturn:'	อัตราผลตอบแทนต่อปี	',
Stakingbalance:'	ยอดเงินเดิมพัน	',
Stakingtotalincome:'	ปักหลักรายได้ทั้งหมด	',
Stakingdetails:'	รายละเอียดการปักหลัก	',
chianclose:'	สกุลเงินนี้ยังไม่ได้เปิด	',
MiningFees:'ค่าธรรมเนียมการขุดไม่เพียงพอ',
},account:{
  WalletID: 'รหัสกระเป๋าเงิน',
  InviteFriends: 'เชิญเพื่อน ๆ',
  DeFiMiningYieldBalance: 'ความสมดุลของผลผลิตการขุด defi',
  Assets: 'สินทรัพย์',
  Withdraw:'ถอน',
  GetRewards :'รับรางวัล',
  YieldBalance: 'ยอดคงเหลือ',
  ReferralRewards: 'รางวัลการอ้างอิง',
  DailyYieldDetails: 'รายละเอียดผลตอบแทนรายวัน',

  WithdrawalHistory: 'ประวัติการถอน',
  Amount: 'จำนวน',
  Status: 'สถานะ',
  Nomoredata: 'ไม่มีข้อมูลเพิ่มเติม',
  complete: 'สมบูรณ์',

  publicchain: 'ห่วงโซ่สาธารณะ',
  time: 'เวลา',
  Assetstip: 'สินทรัพย์',
  Amounttip:'ปริมาณ',
  MinimumWithdrawal: 'จำนวนถอนขั้นต่ำ',
  InsufficientBalance: 'ยอดเงินของคุณไม่เพียงพอ',
  Waitinline: 'การส่งผลงานสำเร็จแล้ว เนื่องจากการถอนเงินจำนวนมาก โปรดรออย่างอดทน',
  

 }

 }
